import React, { useState, useEffect } from 'react';
import { requestWithAuth } from '../fetch.js';
import { JsonEditor } from 'json-edit-react'
import ConfirmationModal from './ConfirmationModal.js';
import C from '../config.js'
import Loader from '../components/Loader.js'

const jsonMock = {
    "middleware": "https://dashboard-test-middleware.aatkit.com",
    "maintenance": false,
    "revenueTool": true,
    "taskScheduler": true,
    "statistics": true,
    "autoKeyGeneration": true,
    "fileServer": true,
    "version": "a7ea6102a6b851d444c9c6f1806fac4a4efd0977",
    "debugURL": "https://api-dev03.aatkit.com/debug"
};

const Pipeline = (props) => {
    const [value, setId] = useState("");
    const [data, setData] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [allJobs, setAllJobs] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [confirmation, setConfirmation] = useState(false)
    const [filter, setFilter] = useState('');
    const [iframeWrapper, setIframeWrapper] = useState(null);
    const [selected, setSelected] = useState(0);
    const [showCreate, setShowCreate] = useState(false);
    const [showVersion, setShowVersion] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [showAll, setShowAll] = useState(false);

    // pipeline
    const [company, setCompany] = useState(null);
    const [project, setProject] = useState(null);
    const [component, setComponent] = useState(null);
    const [branch, setBranch] = useState(null);
    const [env, setEnv] = useState(null);
    const [pipelineConf, setPipelineConf] = useState(null)
    const [validConf, setValidConf] = useState(false)

    // version Checker
    const [projectVersion, setProjectVersion] = useState(null);
    const [envVersion, setEnvVersion] = useState(null);
    const [versionInfo, setVersionInfo] = useState("");

    useEffect(() => {
        getJobs();
        getProjectList();
    }, [])

    async function getProjectList() {
        const projectList = await requestWithAuth('projectList', 'GET', null, 'google');
        setProjectList(projectList.data);
    }

    async function getJobs() {
        const jobs = await requestWithAuth('pipelines/jobs', 'GET', null, 'google');
        setJobs(jobs);
        setAllJobs(jobs);
    }

    async function createJob(pipelineConfirmation) {
        if (!project || !component || !branch || !env | !company) return;
        const { user } = props;
        const { email } = user;
        const data = { owner: email, company, project, component, branch, env, pipelineConfirmation }
        const resText = await requestWithAuth('pipelines/jobs', 'POST', data, 'google');
        setData(resText)
        setConfirmation(false);
        getJobs();
    }

    async function KibanaFrame(id) {
        const baseUrl = `http://node01.elastic.monitoring.aatkit.com:5601/app/discover#/?_g=(filters:!(),refreshInterval:(pause:!t,value:60000),time:(from:now-15M,to:now))&_a=(columns:!(syslog_message),filters:!(),grid:(columns:(container_id:(width:1257))),hideChart:!f,index:b062f836-43a7-4c09-a1f8-19ed00243908,interval:auto,query:(language:kuery,query:'container_id:${id}'),sort:!(!('@timestamp',desc)))`;
        setIframeWrapper(baseUrl);
    }

    function onCancel() {
        setProject(null)
        setComponent(null)
        setBranch(null)
        setEnv(null)
        setPipelineConf(C.pipelines.none);
        setConfirmation(false);
    }

    async function getVersionInfo() {
        const info = await requestWithAuth(`versions/${projectVersion}/${envVersion}`, 'GET', null, 'google');
        setVersionInfo(info);
    }

    function setValue(target, value) {
        let newState = {
            env: null,
            branch: null,
            component: null,
            project: null,
            company: null
        }
        switch (target) {
            case 'env':
                newState.env = value;
                newState.branch = branch;
                newState.component = component;
                newState.project = project;
                newState.company = company;
                break;
            case 'branch':
                newState.branch = value;
                newState.component = component;
                newState.project = project;
                newState.company = company;
                break;
            case 'component':
                newState.component = value;
                newState.project = project;
                newState.company = company;
                break;
            case 'project':
                newState.project = value;
                newState.company = company;
                break;
            case 'company':
                newState.company = value;
                break;
        }
        setEnv(newState.env);
        setBranch(newState.branch);
        setComponent(newState.component);
        setProject(newState.project);
        setCompany(newState.company);
    }

    function onFilter(fil) {
        let filteredJobs = allJobs;
        if (fil != '') {
            filteredJobs = allJobs.filter(j => JSON.stringify(j).includes(fil));
        }
        setJobs(filteredJobs);
        setFilter(fil);
    }

    const createPipeline = (title, onRun, buttonTitle = 'Run') => {
        if (projectList.length === 0) return <Loader />
        return (
            <div className='newPipeline'>
                <h6>{title}</h6>
                {confirmation ? <ConfirmationModal pipeline={{ project, component, branch, env }} onConfirm={(confirmation) => createJob(confirmation)} onCancel={onCancel} /> : null}
                <form style={{ margin: '2%' }} className='pipelineForm'>
                    {projectList && (
                        <select className="form-select small" onChange={(e) => setValue('company', e.target.value)} aria-label="Default select example">
                            <option value={''} >-- Select --</option>
                            {Object.keys(projectList).map((item) => <option key={item} value={item}>{item}</option>)}
                        </select>)}

                    {company && (
                        <select className="form-select small" onChange={(e) => setValue('project', e.target.value)} aria-label="Default select example">
                            <option value={''} >-- Select --</option>
                            {Object.keys(projectList[company]).map((item) => <option key={item} value={item}>{item}</option>)}
                        </select>)}

                    {project && (
                        <select className="form-select small" onChange={(e) => setValue('component', e.target.value)} aria-label="Default select example">
                            <option value={''} >-- Select --</option>
                            {Object.keys(projectList[company][project]).map((item) => <option key={item} value={item}>{item}</option>)}
                        </select>)}

                    {component && projectList[company][project][component].configured_components.artefact && (
                        <select className="form-select small" onChange={(e) => setValue('branch', e.target.value)} aria-label="Default select example">
                            <option value={''} >-- Select --</option>
                            {projectList[company][project][component].configured_components.artefact.target.map((item) => <option key={item} value={item}>{item}</option>)}
                        </select>)}

                    {component && projectList[company][project][component].configured_components.deployment && (
                        <select className="form-select small" onChange={(e) => setValue('env', e.target.value)} aria-label="Default select example">
                            <option value={''} >-- Select --</option>
                            {projectList[company][project][component].configured_components.deployment.target.map((item) => <option key={item} value={item}>{item}</option>)}
                        </select>)}

                    {component && project !== 'dashboard' && projectList[company][project][component].configured_components.deployable && (
                        <select className="form-select small" onChange={(e) => setValue('env', e.target.value)} aria-label="Default select example">
                            <option value={''} >-- Select --</option>
                            {projectList[company][project][component].configured_components.deployable.target.map((item) => <option key={item} value={item}>{item}</option>)}
                        </select>)}

                    <button type="button" className="btn btn-primary" disabled={!env} onClick={onRun}>{buttonTitle}</button>

                </form>
            </div>)
    }

    const checkPipeline = () => (
        <form className='checkPipeline'>
            <h6>Insert pipeline Id</h6>
            <div className="row input-group" style={{ width: '60%', padding: '1%' }}>
                <input value={value} onChange={e => setId(e.target.value)} type="text" className="form-control" style={{ display: 'inline-block' }} />
            </div>
        </form>
    )

    const checkVersion = () => (
        <div className='checkVersion'>
            <h6>Version checker</h6>
            <form style={{ margin: '1%' }}>
                {C.projects && (
                    <select className="form-select small" onChange={(e) => setProjectVersion(e.target.value)} aria-label="Default select example">
                        <option value={''} >-- Select --</option>
                        {Object.keys(C.projects).map((item) => <option key={item} value={item}>{item}</option>)}
                    </select>)}
                {projectVersion && (
                    <select className="form-select small" onChange={(e) => setEnvVersion(e.target.value)} aria-label="Default select example">
                        <option value={''} >-- Select --</option>
                        {Object.keys(C.projects[projectVersion]).map((item) => <option key={item} value={item}>{item}</option>)}
                    </select>)}
                <button type="button" className="btn btn-primary" disabled={!envVersion} onClick={() => getVersionInfo(true)}>Run</button>
            </form>

            {versionInfo && <pre className={"alert alert-info"} role="alert">
                {JSON.stringify(versionInfo, null, 4)}
            </pre>}
        </div>
    )

    const listJobs = () => (
        <div>
            <div className="row" style={{ padding: '1%' }}>
                <div className="col-7 input-group" style={{ width: '60%' }}>
                    <span className="input-group-text" id="basic-addon1">Filter</span>
                    <input value={filter} placehholder="Filter" onChange={e => onFilter(e.target.value)} type="text" className="form-control" />
                </div>
                <div className="col-3">
                    <button style={{ marginRight: '2px' }} type="button" onClick={() => getJobs()} className="btn btn-primary"><i className="bi bi-arrow-clockwise" /> </button>
                    <button style={{ marginRight: '2px' }} type="button" onClick={() => setShowCreate(!showCreate)} className="btn btn-success"><i className="bi bi-plus-square-fill" /> </button>
                    <button style={{ marginRight: '2px' }} type="button" onClick={() => setShowVersion(!showVersion)} className="btn btn-warning"><i className="bi bi-git" /> </button>
                    <button style={{ marginRight: '2px' }} type="button" onClick={() => setShowSearch(!showSearch)} className="btn btn-info"><i className="bi bi-search" /> </button>
                    <button style={{ marginRight: '2px' }} type="button" onClick={() => setShowAll(!showAll)} className="btn btn-secondary"><i className="bi bi-list-columns" /> </button>
                </div>
            </div>

            {showCreate ? createPipeline('Trigger Pipeline', () => setConfirmation(true)) : null}
            {showVersion ? checkVersion() : null}
            {showSearch ? checkPipeline() : null}

            {(jobs && jobs.length > 0) && <div className='row'>
                <div className={showAll ? `App tableFixHead` : `App tableFixHead fixHeight`}>
                    <table className="table table-striped table-hover">
                        <thead className='table-dark'>
                            <tr>
                                <th style={{ width: '150px' }}>Date</th>
                                <th>Owner</th>
                                <th>Project</th>
                                <th>Component</th>
                                <th className='small'>Branch</th>
                                <th className='small'>Env</th>
                                <th className='small'>Build</th>
                                <th className='small'>Commit</th>
                                <th className='large'>pipeline ID</th>
                            </tr>
                        </thead>
                        <tbody>
                            {jobs.map((item, index) => (
                                <tr key={index}>
                                    <td>{new Date(parseInt(item._id.slice(0, 8), 16) * 1000).toLocaleString('de-DE', { timeZone: 'UTC' })}</td>
                                    <td>{item.owner}</td>
                                    <td>{item.project}</td>
                                    <td>{item.component}</td>
                                    <td className='small'>{item.branch}</td>
                                    <td className='small'>{item.env}</td>
                                    <td className='small'>
                                        <a href={`${C.projects.bitbucket.url}${item.company}/${item.project}-${item.component}${C.projects.bitbucket.slug}${item.build}`} target="_blank" className="link-underline-info">
                                            {item.build}
                                        </a>
                                    </td>
                                    <td className='small'>
                                        <a href={`${C.projects.bitbucket.url}${item.company}/${item.project}-${item.component}${C.projects.bitbucket.commitSlug}${item.commit}`} target="_blank" className="link-underline-info">
                                            {item.commit?.substring(0, 8)}
                                        </a>
                                    </td>
                                    <td className='large'>
                                        <a onClick={() => KibanaFrame(item.pipelineId)} href={iframeWrapper} target="_blank" className="link-underline-info">
                                            {item.pipelineId}
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>}
        </div>
    )

    function listBuild() {
        // to DO: before SetValidConf, a method should request the selected configuration
        // To DO: JSON Editor onUpdate should trigger a POST to update conf

        return (
            <div>
                {createPipeline('Select Project', () => setValidConf(jsonMock), "Get")}
                {validConf ? <pre>
                    <JsonEditor
                        data={validConf}
                        onUpdate={(e) => console.log(1, e)}
                        maxWidth={'100%'}
                    />
                </pre> : null}
            </div>
        )
    }


    return (
        <div className="container-fluid">

            <ul className="nav nav-tabs">
                <li className="nav-item" onClick={() => setSelected(0)}>
                    <a className={selected === 0 ? 'nav-link active' : 'nav-link'} aria-current="page" href="#">Pipelines</a>
                </li>
                <li className="nav-item" onClick={() => setSelected(1)}>
                    <a className={selected === 1 ? 'nav-link active' : 'nav-link'} aria-current="page" href="#">Build Conf</a>
                </li>
            </ul>
            {selected === 0 ? listJobs() : null}
            {selected === 1 ? listBuild() : null}
        </div>
    )
};

export default Pipeline