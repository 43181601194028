import React, { useState, useEffect } from 'react';
import { requestWithAuth, post } from '../fetch.js';
import { baseSelect, FilterWrapper, queryStringToObject, Toggle, CheckboxSimple } from '../helpers.js';
import Loader from "../components/Loader.js";
import { AuthLoginModal, isSupplyConnected, AuthChecker } from '../auth.js';
import C from '../config.js'
import Charts from '../components/Charts.js';

const today = new Date().toLocaleDateString('en-CA');
const sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toLocaleDateString('en-CA');

const attrMapper = [
    { label: 'ssp_id', value: 'ssp_id' },
    { label: 'company_ssp', value: 'company_ssp' },
    { label: 'dsp_id', value: 'dsp_id' },
    { label: 'company_dsp', value: 'company_dsp' },
    { label: 'country', value: 'country' },
    { label: 'traffic', value: 'traffic' },
    { label: 'ad_format', value: 'ad_format' },
    { label: 'domain', value: 'domain' },
    { label: 'size', value: 'size' },
    { label: 'os', value: 'os' },
    { label: 'crid', value: 'crid' },
    { label: 'consent_regulation_compliance', value: 'consent_regulation_compliance' },
    { label: 'ssp_name', value: 'ssp_name' },
    { label: 'dsp_name', value: 'dsp_name' },
]

const metricsMapper = [
    { label: 'ssp_requests', value: 'ssp_requests' },
    { label: 'bid_requests', value: 'bid_requests' },
    { label: 'bid_responses', value: 'bid_responses' },
    { label: 'dsp_wins', value: 'dsp_wins' },
    { label: 'ssp_wins', value: 'ssp_wins' },
    { label: 'impressions', value: 'impressions' },
    { label: 'clicks', value: 'clicks' },
    { label: 'ssp_revenue', value: 'ssp_revenue' },
    { label: 'ssp_ecpm', value: 'ssp_ecpm' },
    { label: 'avg_ssp_bid_price', value: 'avg_ssp_bid_price' },
    { label: 'avg_ssp_bid_floor', value: 'avg_ssp_bid_floor' },
    { label: 'avg_dsp_bid_price', value: 'avg_dsp_bid_price' },
    { label: 'avg_dsp_bid_floor', value: 'avg_dsp_bid_floor' },
    { label: 'dsp_spend', value: 'dsp_spend' },
    { label: 'dsp_ecpm', value: 'dsp_ecpm' },
    { label: 'profit', value: 'profit' },
    { label: 'bid_rate', value: 'bid_rate' },
]

const granularityMapper = [
    { label: 'month', value: 'month' },
    { label: 'day', value: 'day' },
    { label: 'total', value: "total" }
]

const isNumeric = (num) => (typeof (num) === 'number' || typeof (num) === "string" && num.trim() !== '') && !isNaN(num);
function getColorFromPercentage(percentage) {
    // Ensure percentage is within bounds
    percentage = Math.max(-100, Math.min(100, percentage));
    // Convert percentage to a hue value between 0 (red) and 120 (green)
    // -100% maps to 0 (red), 0% maps to 60 (yellow), 100% maps to 120 (green)
    const hue = (percentage + 100) * 0.6;
    // Use a saturation and lightness of 100% for vivid colors
    return `hsl(${hue}, 100%, 50%)`;
}

const groupByDate = (arr) => {
    return arr.reduce((acc, obj) => {
        // Extract date without time (set hours to 00:00:00)
        const dateKey = new Date(obj.date).toISOString().split('T')[0]; // "YYYY-MM-DD"

        // If the date is not already a key, create it and initialize an array
        if (!acc[dateKey]) {
            acc[dateKey] = [];
        }

        // Push the current object to the corresponding date group
        acc[dateKey].push(obj);

        return acc;
    }, {});
};

function getRndInteger() {
    return Math.floor(Math.random() * (1000000000000000 - 0 + 1)) + 0;
}

function getLargestPropertyArray(obj) {
    // Filter properties that are arrays
    const arrays = Object.values(obj).filter(value => Array.isArray(value));

    // If no arrays are found, return an empty array
    if (arrays.length === 0) return [];

    // Find the largest array based on length
    return arrays.reduce((largest, current) =>
        current.length > largest.length ? current : largest
    );
}


const BluePages = (props) => {
    const [apiQuery, setApiQuery] = useState(null);
    const [selectedKpi, setSelectedKpi] = useState('ssp_revenue');
    const [selectedDim, setSelectedDim] = useState(['company_dsp']);
    const [selectedFilter, setSelectedFilter] = useState([]);
    const [filters, setFilters] = useState([0]);
    const [metrics, setMetrics] = useState(metricsMapper);
    const [startDate, setStartDate] = useState(sevenDaysAgo);
    const [endDate, setEndDate] = useState(today);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [originalHeaders, setOriginalHeaders] = useState([])
    const [tableValues, setTableValues] = useState([]);
    const [originalValues, setOriginalValues] = useState([]);
    const [processData, setProcessData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showQuery, setShowQuery] = useState(false);
    const [processedNameFilter, setProcessedNameFilter] = useState('')
    const [hideGB, setHideGB] = useState(true)
    const [rawData, setRawData] = useState(null);
    const [originalProcessedValues, setOriginalProcessedValues] = useState();
    const [lastFetched, setLastFetched] = useState(null);
    const [granularity, setGranularity] = useState('day');
    const [toChart, setToChart] = useState([]);
    const [extendedMode, setExtendedMode] = useState(false)
    const [nk, setNk] = useState([])
    const [totalsInfo, setTotalsInfo] = useState(null)
    const [filterDimensions, setFilterDimensions] = useState([]);
    const [isResultGroupedByAccount, setIsResultGroupedByAccount] = useState(false);
    const [kpiAfterProd, setKpiAfterProd] = useState();


    const token = props.user?.token;
    const supplyConnected = isSupplyConnected()

    async function fetchNk() {
        const rtbNk = await post(C.projects.core.api + '/reports/dashboardStats', {
            query: '/network-keys?networkId=' + C.graviteRtbId
        }, false, false, false);
        setNk(rtbNk);
    }

    async function fetchStats() {
        setLoading(true);
        setLastFetched(new Date());
        if (supplyConnected) await fetchNk();
        const statsResult = await requestWithAuth('reports/demandStats', 'POST', { reportUrl: apiQuery }, 'google');
        if (statsResult.message && statsResult.message.includes('Token invalid')) {
            props.onLogout()
            return;
        } else {
            FilterData(statsResult)
        }
    }

    useEffect(() => {
        createQuery();
    }, [selectedDim, token, startDate, endDate, granularity, selectedFilter, metrics])

    useEffect(() => {
        FilterData(rawData);
    }, [selectedKpi])

    async function AddSelector(dim) {
        if (dim && dim.length) {
            const res = [];
            dim.forEach(a => res.push(a.value))
            setSelectedDim(res)
        }
    }

    function FilterData(statsResult) {
        if (!statsResult) return;
        if (statsResult.message && statsResult.message.includes('Token invalid')) return;

        setTotalsInfo({ totals: statsResult.totals, totalPages: statsResult.totalPages })
        const statsResultRawData = statsResult.data;

        // sort output
        const objectOrder = {};
        selectedDim.forEach(d => objectOrder[d] = null);
        objectOrder.date = null;
        const addObjectResource = Object.assign(objectOrder, statsResultRawData[0]);
        const dataSorted = [];
        statsResultRawData.forEach(r => {
            const sortedRow = Object.assign(objectOrder, r);
            dataSorted.push(Object.values(sortedRow));
        })
        setRawData(statsResult)
        setTableHeaders(Object.keys(addObjectResource));
        setOriginalHeaders(Object.keys(addObjectResource))
        setTableValues(dataSorted);
        setOriginalValues(dataSorted);
        setLoading(false);
    }

    function prepareProcessedData(metricSelected) {
        setKpiAfterProd(metricSelected);
        setToChart([])
        setProcessData(false)
        if (metricSelected === 1) {
            setTableHeaders(originalHeaders);
            setTableValues(originalValues)
            return;
        }
        setTableHeaders(null)
        setTableValues(null)
        setLoading(true);
        let statsResultRawData = [...rawData.data];
        if (hideGB && selectedDim.includes('company_dsp')) statsResultRawData = statsResultRawData.filter(a => a.company_dsp !== 'Google Bidding')

        statsResultRawData.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(a.date) - new Date(b.date);
        });

        const groupBy = selectedDim
        const dateAsColumn = groupByDate(statsResultRawData);
        const headers = [...['Selector'], ...groupBy, ...Object.keys(dateAsColumn)];
        const finalSet = [];
        const largestDateData = getLargestPropertyArray(dateAsColumn);
        largestDateData.map(a => {
            let res = [];
            groupBy.forEach(gb => res.push(a[gb]));
            finalSet.push(res);
        })

        Object.keys(dateAsColumn).map(d => {
            // get object array per date
            let set = dateAsColumn[d];
            // I go through the original array wich contains all the posible combinations
            finalSet.map((finalSetItem, finalSetIndex) => {
                // I look for coincidences between so and finalSetItem
                const founded = set.find(so => {
                    let res = false;

                    // sort object before turning into array
                    const objectOrder = {};
                    objectOrder.date = null;
                    selectedDim.forEach(d => objectOrder[d] = null);
                    const rawVal = Object.assign(objectOrder, so);

                    let val = Object.values(rawVal);

                    const subArrayFromSet = val.slice(0, groupBy.length + 1);
                    let match = [d, ...finalSetItem].slice(0, groupBy.length + 1);
                    if (JSON.stringify(subArrayFromSet) == JSON.stringify(match)) res = true
                    return res;
                })
                if (founded) {
                    finalSetItem.push(founded[metricSelected])
                }
            })
        })

        if (selectedDim.includes('domain')) {
            const newDomainDimensions = ['App', 'Account', 'AccountId'];
            if (nk.length) {
                headers.splice(selectedDim.length + 1, 0, ...newDomainDimensions);
                setFilterDimensions([...groupBy, ...newDomainDimensions])
            }
            let domainIn = headers.findIndex(f => f == 'domain') - 1
            finalSet.forEach(r => {
                let value = r[domainIn];
                let ex = nk.find(f => f.networkKey.includes(value));
                if (ex && ex.label && ex.appName) {
                    const accName = ex.label.split('_')[0]
                    r.splice(domainIn + 1, 0, ...[`${ex.appName}[${ex.appId}]`, `${accName}`, '_' + ex.accountId])
                    r[domainIn] = value + ` [${ex.label}]`
                } else {
                    r[domainIn] = value + `...(not synced)`
                }
            })
        }


        function sumNumbers(arr) {
            if (!arr) return 0;
            return arr.reduce((sum, num) => sum + (typeof num === "number" ? num : 0), 0);
        }

        function sumByIndex(arrays, index) {
            if (!arrays) return 0;
            return arrays.reduce((sum, subArray) => sum + (typeof subArray[index] === "number" ? subArray[index] : 0), 0);
        }

        // get totals per Row
        finalSet.forEach(fs => {
            const arrToReduce = [...fs];
            selectedDim.forEach(() => arrToReduce.shift())
            const totalPerRow = sumNumbers(arrToReduce)
            fs.push(totalPerRow)
        })

        // get totals per column
        const totalRow = [];
        groupBy.forEach(() => totalRow.push('Totals'));
        // headers when domain & supply is present
        if (selectedDim.includes('domain') && nk.length) totalRow.push('-', '-', '-')
        for (let i = totalRow.length; i < headers.length; i++) {
            const totalPerColumn = sumByIndex(finalSet, i)
            totalRow.push(totalPerColumn)
        }

        headers.push('Totals')

        setTableHeaders(headers)
        setTableValues([...[totalRow], ...finalSet])
        setProcessData(true)
        setLoading(false);
    }

    function createQuery() {
        let finalQuery = token + '/adx-report';
        finalQuery += '?attribute[]=' + selectedDim[0];
        if (selectedDim.length > 1) {
            selectedDim.slice(1).forEach((element, index) => {
                finalQuery += '&attribute[]=' + element;
            });
        }
        if (selectedFilter.length) {
            selectedFilter.forEach((element, index) => {
                finalQuery += `&filter[${element.key}][]=${element.value}`;
            });
        }
        if (metrics.length) {
            metrics.forEach(m => {
                finalQuery += `&metric[]=${m.value}`
            })
        }
        finalQuery += '&from=' + startDate + '&to=' + endDate;
        finalQuery += '&day_group=' + granularity;
        finalQuery += '&limit=10000'
        setApiQuery(finalQuery);
    }

    function AddFilterRow() {
        return FilterWrapper(selectedDim, (filtered) => {
            if (filtered.value && filtered.key) {
                setSelectedFilter([...selectedFilter, filtered])
                setFilters([...filters, [0]])
            }
        })
    }

    function sortingFn() {
        function filterBy(kpi) {
            const index = tableHeaders.findIndex(i => i === kpi);
            const newTableValues = [...originalValues];
            newTableValues.sort((a, b) => {
                const xA = isNumeric(a[index]) ? parseFloat(a[index]) : 0;
                const xB = isNumeric(b[index]) ? parseFloat(b[index]) : 0;
                return xB - xA
            });
            setTableValues(newTableValues);
        }
        return metrics && metrics.length ? baseSelect({
            defaultValue: null,
            options: metrics,
            onChange: (d) => {
                filterBy(d.value)
            },
        }) : null
    }

    function appendSupplyInfo(val, i) {
        if (selectedDim.includes('domain')) {
            let domainIn = tableHeaders.findIndex(f => f == 'domain')
            if (domainIn == i) {
                let ex = nk.find(f => f.networkKey.includes(val))
                if (ex && ex.label && ex.appName) return val + ` (${ex.appName} - [${ex.label}])`
                return val + ' undefined'
            } else {
                return val
            }
        } else {
            return val;
        }
    }

    function renderProcessedData() {

        function renderBlock(data) {
            if (!data) return;
            // extract first two rows (title and dim)
            let subarray = [...data];
            if (processedNameFilter) {
                let index = filterDimensions.indexOf(processedNameFilter.key);
                subarray = subarray.filter(f => f[index].includes(processedNameFilter.value))
            }

            const sortedArr = subarray.filter(a => a.filter(Number).length).sort((a, b) => {
                if (a.filter(Number).length > 0 && b.filter(Number).length > 0) {
                    // filter to get just numbers
                    const filteredArrA = a.filter(Number);
                    if (filteredArrA.length) {
                        // sum A arr
                        const sumA = filteredArrA.map(el => parseFloat(el)).reduce((a, b) => a + b);
                        // filter to get just numbers
                        const filteredArrB = b.filter(Number);
                        // sum A arr
                        const sumB = filteredArrB.map(el => parseFloat(el)).reduce((a, b) => a + b);
                        return sumB - sumA;
                    }
                }
            });

            // headers
            const headers = tableHeaders

            sortedArr.forEach((e, index) => {
                // headers has the whole length - title and total 
                for (let i = 1; i < length; i++) {
                    if (e[i] === undefined || e[i] === NaN || !e[i]) {
                        sortedArr[index][i] = 0;
                    }
                }
            })

            function isChecked(val) {
                // check if is there
                const name = val[0];
                // filter chart
                const nameArr = toChart.map(a => a[0]);
                return nameArr.includes(name)
            }

            function toDraw(val) {
                // check if is there
                const name = val[0];
                // filter chart
                const nameArr = toChart.map(a => a[0]);
                let newChart = [...toChart];
                if (!nameArr.includes(name)) {
                    newChart.push(val);
                } else {
                    const indexArr = toChart.findIndex(a => a[0] == name);
                    newChart.splice(indexArr, 1);
                }
                setToChart(newChart)
            }

            return <div>
                <Charts values={toChart} headers={headers} kpi={kpiAfterProd}/>
                <table className='table tbl table-striped table-bordered'>
                    <thead>
                        <tr>{headers.map(h => <th key={h + getRndInteger()}>{h}</th>)}</tr>
                    </thead>
                    <tbody>
                        {sortedArr.map((value, index) => { //Rows
                            let average = 0;
                            // calculate avg
                            if (value && value.filter(Number).length > 0) {
                                const filteredArr = value.filter(Number);
                                // remove totals
                                filteredArr.pop()
                                if (filteredArr.length) average = filteredArr.map(el => parseFloat(el)).reduce((a, b) => a + b) / filteredArr.length;
                            }
                            //skipping GAM/MCM
                            const totalsColumn = (value[0] === 'Totals');

                            const trKey = `${value[0]}-${selectedKpi}-${index}-${getRndInteger()}-tr`;

                            return (
                                <tr key={trKey} className={totalsColumn ? 'title' : ''}>
                                    <td>
                                        <CheckboxSimple
                                            label={''}
                                            onChange={() => toDraw(value)}
                                            checked={isChecked(value)}
                                        />
                                    </td>
                                    {value.map((val, i) => { //Columns
                                        let bg = 'none';
                                        const tdKey = `${value[0]}-${selectedKpi}-${index}-${val}-${i}-${getRndInteger()}`;
                                        let finalValue = val
                                        const delta = 100 * ((val - average) / Math.abs(average));
                                        if (isNumeric(val)) {
                                            bg = getColorFromPercentage(delta)
                                            finalValue = parseFloat(val).toFixed(2).toLocaleString();
                                            return <td key={`${tdKey}-num`} className='test' style={{ backgroundColor: bg }}>{finalValue}</td>;
                                        } else {
                                            // filter per account
                                            return <td className='test' key={`${tdKey}-acc`}>{finalValue}</td>;
                                        }
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        }

        function GroupProcessedResultByAccountId() {
            if (isResultGroupedByAccount) {
                setTableValues(originalProcessedValues)
                setIsResultGroupedByAccount(false)
                return;
            }
            // remove totals
            const res = [...tableValues]
            // remove totals
            res.shift();
            // gets AccountID index
            const accIndex = [3]
            const groupAndSumByIndex = (arrays, groupIndex) => {
                return Object.values(
                    arrays.reduce((acc, curr) => {
                        const key = curr[groupIndex];
                        if (!acc[key]) {
                            acc[key] = [...curr];
                        } else {
                            acc[key] = acc[key].map((val, index) =>
                                index === groupIndex ? val : (val || 0) + (curr[index] || 0)
                            );
                        }
                        return acc;
                    }, {})
                );
            };
            const groupedByAcc = groupAndSumByIndex(res, accIndex);
            groupedByAcc.forEach(g => {
                const accNameRaw = g[0].split('[')[1];
                const accName = accNameRaw.split('_')[0];
                const accId = g[3].split('_')[1]
                g[0] = accName;
                g[1] = '-';
                g[2] = accName;
                g[3] = '_' + accId;
            })
            setOriginalProcessedValues(tableValues)
            setTableValues([tableValues[0], ...groupedByAcc])
            setIsResultGroupedByAccount(true)
        }

        return (
            <div>
                <div className="input-group mb-3">
                    {FilterWrapper(filterDimensions, (f) => setProcessedNameFilter(f))}
                    {selectedDim.includes('domain') ? <>
                        <CheckboxSimple
                            label="Group by Account"
                            onChange={() => GroupProcessedResultByAccountId()}
                        />
                    </> : null}
                </div>
                <div className="row justify-content-start">
                    <div className="col-12">
                        {renderBlock(tableValues)}
                    </div>
                </div>
            </div>
        )
    }

    function renderUnprocessedData() {
        if (!tableValues.length) return <p>No Data</p>;
        return (
            <div>
                <div>
                    <h6>Options</h6>
                    <ul style={{ display: 'inline-block' }}>
                        <li>Sort by: {sortingFn()}</li>
                    </ul>
                </div>

                <table className='table tbl table-striped table-bordered'>
                    <thead><tr>{tableHeaders.map((rows, index) => <th className='header table-warning' key={index}>{rows}</th>)}</tr></thead>
                    <tbody>{tableValues.map((value, index) => <tr key={index}>{value.map((val, i) => <td key={i}>{appendSupplyInfo(val, i)}</td>)}</tr>)}</tbody>
                </table>
            </div>
        );
    }

    return (
        <div style={{ width: '90%', margin: 'auto' }}>
            <div>
                {AuthChecker('supply')}
                <Toggle
                    title="Extended mode"
                    onChange={() => setExtendedMode(!extendedMode)}
                />
                <div className="row align-items-start" style={{ display: 'inline-flex', margin: '2%', width: '94%' }}>
                    <div className={extendedMode ? "col-9 extendedMode" : "col-9"}>

                        {baseSelect({
                            title: 'Granularity',
                            extendedMode,
                            defaultValue: granularityMapper[1],
                            options: granularityMapper,
                            onChange: (e) => setGranularity(e.value)
                        })}

                        {baseSelect({
                            title: 'Group By',
                            extendedMode,
                            defaultValue: attrMapper[3],
                            options: attrMapper,
                            isMulti: true,
                            onChange: (e) => AddSelector(e)
                        })}

                        {baseSelect({
                            title: 'Metrics',
                            extendedMode,
                            defaultValue: metrics,
                            options: metricsMapper,
                            isMulti: true,
                            onChange: (e) => setMetrics(e)
                        })}

                        <div>
                            <h5>Filters</h5>
                            <div style={{ overflow: 'visisble' }}>
                                <button type="button" className="btn btn-link" onClick={() => setSelectedFilter([])}>Reset</button>
                                {filters.map((a, index) => <div key={index}>{AddFilterRow()}</div>)}
                            </div>
                        </div>
                    </div>

                    <div className='col-3'>
                        <h5 htmlFor="start">From-To</h5>
                        <input style={{ float: 'left' }} type="date" id="start" name="trip-start" value={startDate} min="2018-01-01" max={today} onChange={(e) => setStartDate(new Date(e.target.value).toLocaleDateString('en-CA'))} />
                        <input type="date" id="end" name="trip-start" value={endDate} min="2018-01-01" max={today} onChange={(e) => setEndDate(new Date(e.target.value).toLocaleDateString('en-CA'))} />
                        <div className="col" style={{ display: "grid", marginTop: '4%' }}>
                            <CheckboxSimple
                                label="Show API Query"
                                value={showQuery}
                                onChange={() => setShowQuery(!showQuery)}
                            />
                            <CheckboxSimple
                                label="Include GBidding"
                                value={hideGB}
                                onChange={() => setHideGB(!hideGB)}
                            />
                        </div>
                    </div>
                </div>
                <br />
                {showQuery && <div className="form-group">
                    {queryStringToObject(C.projects.rtbApi.api + '/' + apiQuery)}
                </div>}
                <button type="button" style={{ marginBottom: '50px' }} className="btn btn-primary" onClick={fetchStats}>Run</button>
                <br />
                {totalsInfo?.totalPages > 1 ? <div className="alert alert-warning" role="alert">Too many results, please use <b>Filters</b></div> : null}
                {lastFetched ? <div style={{ float: 'right' }}>
                    {!processData && totalsInfo && totalsInfo ? <span ><b>Total results:</b> {totalsInfo.totals} (pages: {totalsInfo.totalPages})</span> : null}
                    <button type="button" onClick={() => fetchStats()} className="btn btn-link"><i className="bi bi-arrow-clockwise" /> </button>
                    <span >Last sync: {lastFetched.toLocaleDateString()}: {lastFetched.toLocaleTimeString()}</span>
                </div> : null}
                {tableHeaders && tableHeaders.length > 0 && <>
                    <div style={{ display: 'inline-flex', width: '300px' }}>
                        Process Result by
                        {baseSelect({
                            defaultValue: null,
                            options: [{ label: 'Off', value: 1 }, ...metrics],
                            onChange: (e) => prepareProcessedData(e.value)
                        })}
                    </div>
                </>}
                {processData && !loading && renderProcessedData()}
                <br />
                {tableHeaders && !loading && !processData && renderUnprocessedData()}
                {loading ? <Loader /> : null}

            </div>
        </div>
    )
};

const Wrapper = () => {
    return (<AuthLoginModal target='demand' style='rtb' open={true} fallback={(user, onLogout) => <BluePages user={user} onLogout={onLogout} />} />);
}

export default Wrapper