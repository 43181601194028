import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const removeFirstSecondLast = (arr) => {
    if (arr.length <= 3) return []; // not enough items to keep
    return arr.slice(2, -1); // skip index 0 and 1, exclude the last
};

const getRandomHexColor = () => {
    return '#' + Math.floor(Math.random() * 0xffffff).toString(16).padStart(6, '0');
  };

const removeAllSpaces = str => str.replace(/\s+/g, '');

const chart = ({ values, headers, kpi }) => {
    const [data, setData] = useState([])
    const [keys, setKeys] = useState([])

    useEffect(() => {
        setData([]);
        setKeys([])
    }, [kpi])

    useEffect(() => {
        const data = createData();
        setData(data);
        if (data.length) setKeys(Object.keys(data[0]).filter(a => a != 'date'))
    }, [values])

    useEffect(() => {
        const data = createData();
        setData(data);
        if (data.length) setKeys(Object.keys(data[0]).filter(a => a != 'date'))
    }, [])

    function createData() {
        const res = [];
        const time = removeFirstSecondLast(headers);
        time.map((d, j) => {
            const obj = { date: d }
            values.map((v, i) => {
                const varName = removeAllSpaces(v[0])
                obj[varName] = v[j + 1]
            })
            res.push(obj)
        });
        return res;
    }

    return (
        <ResponsiveContainer width={"100%"} height={300} style={{marginBottom: '5%'}}>
            <LineChart data={data} margin={{ top: 20 }} accessibilityLayer>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" padding={{ left: 30, right: 30 }} />
                <YAxis />
                <Tooltip />
                <Legend />
                {keys.map(d => <Line
                    key={d}
                    type="monotone"
                    dataKey={d}
                    stroke={getRandomHexColor()}
                    activeDot={{ r: 8 }}
                />)}
            </LineChart>
        </ResponsiveContainer>
    );
}

export default chart
