import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import Papa from "papaparse";
import { CSVLink } from 'react-csv'
import { get, post, requestWithAuth } from '../fetch.js';
import Loader from "../components/Loader.js";
import { baseSelect, queryStringToObject, Toggle } from '../helpers.js';
import C from '../config.js'

const today = new Date().toLocaleDateString('en-CA');
const sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toLocaleDateString('en-CA');

// helpers
const Checkbox = ({ label, value, onChange, disabled, array, sublabel, checked }) => {
    function isChecked() {
        let res = (array) ? array.includes(value) : value
        if (checked) res = checked(value);
        return res;
    }
    return (
        <label style={{ margin: '0 1%' }}>
            <input className="form-check-input" type="checkbox" value={value} checked={isChecked()} onChange={onChange} disabled={disabled} />
            {' ' + label}{sublabel ? <p className='sublabel'>{'[' + sublabel + ']'}</p> : ''}
        </label>
    );
};
const isNumeric = (num) => (typeof (num) === 'number' || typeof (num) === "string" && num.trim() !== '') && !isNaN(num);
function getColorFromPercentage(percentage) {
    // Ensure percentage is within bounds
    percentage = Math.max(-100, Math.min(100, percentage));
    // Convert percentage to a hue value between 0 (red) and 120 (green)
    // -100% maps to 0 (red), 0% maps to 60 (yellow), 100% maps to 120 (green)
    const hue = (percentage + 100) * 0.6;
    // Use a saturation and lightness of 100% for vivid colors
    return `hsl(${hue}, 100%, 50%)`;
}

const SupplyPages = (props) => {
    const [apiQuery, setApiQuery] = useState(null);
    const [dimensions, setDimensions] = useState([]);
    const [kpis, setKpis] = useState([]);
    const [selectedDim, setSelectedDim] = useState(['accountId']);
    const [filteredDim, setFilteredDim] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState([]);
    const [metrics, setMetrics] = useState();
    const [startDate, setStartDate] = useState(sevenDaysAgo);
    const [endDate, setEndDate] = useState(today);
    const [selectedSource, setSelectedSource] = useState(['revenues']);
    const [data, setData] = useState({});
    const [tableRows, setTableRows] = useState([]);
    const [tableValues, setTableValues] = useState([]);
    const [parsedData, setParsedData] = useState([]);
    const [dateAsColumns, setDateAsColumns] = useState(false);
    const [processData, setProcessData] = useState(false);
    const [showAdvance, setShowAdvance] = useState(false);
    const [JSONFormat, setJSONFormat] = useState(true);
    const [loading, setLoading] = useState(false);
    const [showMCM, setShowMCM] = useState(false);
    const [showQuery, setShowQuery] = useState(false);
    const [getBD, setGetBD] = useState(false);
    const [bdAccountList, setBdAccountList] = useState([]);
    const [runDisabled, setRunDisabled] = useState(false);
    const [showId, setShowId] = useState(false);
    const [processedNameFilter, setProcessedNameFilter] = useState(null);
    const [JSONRaw, setJSONRaw] = useState(null);
    const [statisticsApi, setStatisticsApi] = useState(false);
    const [justTotals, setJustTotals] = useState(false);
    const [expand, setExpand] = useState(null)
    const [JSONRawData, setJSONRawData] = useState(null);
    const [extendedMode, setExtendedMode] = useState(false)
    const [cluster, setCluster] = useState(0);
    const [totalsInfo, setTotalsInfo] = useState(null)

    useEffect(() => {
        fetchConstants();
    }, [])

    useEffect(() => {
        if (statisticsApi && !data.accountId) {
            setAndfilter('accountId');
        }
    }, [statisticsApi])

    useEffect(() => {
        fetchBDAndReload();
    }, [getBD])

    useEffect(() => {
        createQuery();
    }, [selectedDim, selectedFilter, selectedSource, dateAsColumns, JSONFormat, startDate, endDate, bdAccountList, getBD, showId])

    const csvLink = useRef() // setup the ref that we'll use for the hidden CsvLink click once we've updated the data

    async function fetchConstants() {
        const cacheRes = await requestWithAuth('reports/cacheResources?type=gravite');
        const constants = cacheRes.constants;
        setDimensions(constants.stats.statistics.tabs);
        let metricList = [];
        constants.stats.statistics.filter.forEach(e => {
            metricList.push({ label: e.itemLabel, value: e })
        })
        setKpis(metricList);
        const newData = { ...data };
        newData.consentType = constants.consentTypes;
        newData.consentValue = constants.consentValues;
        setData(newData);
    }

    function afterJSONProcessing(data, metricName = 'revenues') {
        setTableValues([])
        // headers
        // Id header/column is important for expand filtering
        const columnsToAdd = [{ content: 'ID' }];
        if (data.reply.data.series.length == 0) return <p>No Data</p>
        const returnedDim = data.reply.data.series[0].dim;
        returnedDim.forEach(d => columnsToAdd.push({ content: d }))
        let metricHeaders = [...columnsToAdd, ...data.request.query.metric];
        // if headers includes Revenues & NetRevenues
        const metIndex = metricHeaders.findIndex(item => item.content.toLowerCase() === metricName.toLowerCase());

        // Rows
        const rows = [];
        data.reply.data.series.forEach((row) => {
            // dates columns
            const line = [];
            if (row.dim.length < 2) {
                // Id header/column is important for expand filtering
                line.push([row.id], [row.label])
            } else {
                line.push(row.id)
                row.id.forEach((id, index) => {
                    line.push([`${row.label[index]} [${id}]`])
                })
            }
            row.total.forEach(r => line.push(r))
            rows.push(line);
        });
        // sort descendant by revenue (index 2 on metrics, selectedMetric)
        rows.sort((a, b) => {
            const xA = isNumeric(a[metIndex]) ? parseFloat(a[metIndex]) : 0;
            const xB = isNumeric(b[metIndex]) ? parseFloat(b[metIndex]) : 0;
            return xB - xA
        });
        // totals
        const totalRow = ['Totals'];
        if (returnedDim.length > 1) returnedDim.forEach(a => totalRow.push('-'))
        data.reply.data.total.forEach((t) => totalRow.push(t));
        rows.push(totalRow);

        setTotalsInfo({ totals: rows.length })

        setJSONRawData(data);
        setTableRows(metricHeaders)
        setTableValues(rows)
    }

    async function fetchStats() {
        setLoading(true);
        const statsResultRaw = await post(C.projects.core.api + '/reports/dashboardStats', {
            query: apiQuery
        }, false, false, true);
        let statsResult;
        const isCSVFormat = apiQuery.includes('download-statistics')
        if (apiQuery.includes('api.aatkit.com')) {
            const jsonRes = await statsResultRaw.json();
            setStatisticsApi(true)
            setJSONRaw(jsonRes)
        }
        // parse CSV file
        else if (isCSVFormat) {
            statsResult = await statsResultRaw.text()
            Papa.parse(statsResult, {
                complete: function (results) {
                    const rowsArray = [];
                    const valuesArray = [];
                    // Iterating data to get column name and their values
                    results.data.map((d) => {
                        rowsArray.push(Object.keys(d));
                        valuesArray.push(Object.values(d));
                    });
                    // Parsed Data Response in array format
                    setParsedData(results.data);
                    // Filtered Column Names
                    setTableRows(rowsArray[0]);
                    // Filtered Values
                    setTableValues(valuesArray);
                },
            });
        } else {
            statsResult = await statsResultRaw.json();
            const metrics = [...statsResult.request.query.metric];
            if (metrics.length) setMetrics(metrics)
            afterJSONProcessing(statsResult)
        }
        setLoading(false)
    }

    async function fetchBDAndReload() {
        if (getBD) {
            let a = new Date();
            const newData = new Date(a.setMonth(a.getMonth() - 14))
            const month = (newData.getMonth() < 10) ? `0${newData.getMonth()}` : newData.getMonth();
            const bdAccounts = await get(`${C.projects.dashboard.stage.api + '/accounts/revenueDates?from=' + newData.getFullYear() + '-' + month + '&techProvider=false&type=revenueStart&number=1000'}`,true);
            const bdAccountIdArr = [];
            bdAccounts.map(ac => bdAccountIdArr.push(ac.accountId));
            setBdAccountList(bdAccountIdArr);
        }
    }

    function createQuery() {
        let finalQuery = '/';
        finalQuery += JSONFormat ? 'statistics?' : 'download-statistics?';
        finalQuery += 'selector=' + selectedDim.toString() + ',eventDay';
        finalQuery += '&dateAsColumns=' + dateAsColumns;
        selectedFilter.forEach(element => {
            finalQuery += '&' + element.dim + '=' + element.value;
        });
        if (showId) finalQuery += '&userSettings=true'
        finalQuery += '&from=' + startDate + '&to=' + endDate;
        let finalSources = selectedSource;
        selectedSource.forEach(element => {
            finalQuery += '&' + element + '=true';
        });
        const uniqueAccountSelected = selectedFilter.some(a => a.dim === "accountId");
        if (cluster) finalQuery += '&clusterId=' + cluster;
        if (getBD && bdAccountList.length && !uniqueAccountSelected) {
            finalQuery += '&accountId=' + bdAccountList.toString();
        }
        if (finalSources.includes('user')) {
            let invalidUserConf = false;
            selectedDim.forEach(d => {
                if (!['appId', 'platformId', 'countryId', 'deviceId'].includes(d)) invalidUserConf = true
            })
            if (invalidUserConf) {
                window.alert('Your selected configuration is not valid, Users information is only valid with the following dimensions: "App, Country, Platform, Device"')
                setRunDisabled(true)
            }
        } else {
            setRunDisabled(false)
        }
        setApiQuery(finalQuery);
    }

    async function AddFilter(dim) {
        if (filteredDim.includes(dim)) {
            setFilteredDim(filteredDim.filter(item => item !== dim))
        } else {
            setFilteredDim(filteredDim => [...filteredDim, dim])
        }
    }

    async function AddSelectetdFilter(value, dim) {
        const finalObject = { value: value.id, dim }
        if (selectedFilter.some(e => e.dim === dim)) {
            setSelectedFilter(selectedFilter.filter(item => item.dim !== dim))
        } else {
            setSelectedFilter(selectedFilter => [...selectedFilter, finalObject])
        }
    }

    async function setAndfilter(dim) {
        if (dim.includes('Id')) {
            let endpoint = C.endpointMapper[dim];
            const info = await post('reports/dashboardStats', { query: '/' + endpoint });
            const newData = { ...data };
            newData[dim] = info;
            setData(newData);
        }
    }

    function load(dim) {
        if (filteredDim.includes(dim)) {
            if (!data[dim]) {
                setAndfilter(dim);
                return <div>Loading</div>
            }
            else {
                return <Select
                    defaultValue={[]}
                    // isMulti
                    name="colors"
                    options={data[dim]}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => AddSelectetdFilter(e, dim)}
                />
            }
        }
    }

    function renderExpanded(originalRow) {
        const copyValue = [...originalRow]
        function insertArrayAt(array, index, arrayToInsert) {
            Array.prototype.splice.apply(array, [index, 0].concat(arrayToInsert));
            return array;
        }
        // originalColumn - dimensions - Id column
        const statsIndex = expand - (selectedDim.length) - 1;
        // originalData contains extended data
        const originalData = [...JSONRawData?.reply?.data?.series]
        // expanded Data (object by id (originalRow[0]))
        const serie = originalData.find((element) => element.id == originalRow[0]);
        let expandedValues = serie?.stats[statsIndex];
        // totals Row case [last one] originalRow[0] is ID
        if (originalRow[0] === 'Totals') {
            // dateRange 
            const dailyTotals = [];
            // for Each Day
            JSONRawData?.reply.time.forEach((a, i) => {
                // for each serie  emptyArr.push('-'))
                dailyTotals[i] = 0;
                originalData.forEach(it => dailyTotals[i] += it.stats[statsIndex][i])
            });
            expandedValues = dailyTotals
        }
        const newArray = insertArrayAt(copyValue, expand, expandedValues);
        const comparedIndexes = [];
        let average = 0;
        // average
        if (expandedValues && expandedValues.length) {
            expandedValues.forEach((a, i) => comparedIndexes.push(i + expand));
            comparedIndexes.push(expandedValues.length + expand)
            average = expandedValues.map(el => isNumeric(el) ? parseFloat(el) : 0).reduce((a, b) => a + b) / expandedValues.length;
        }

        return newArray.map((val, i) => {
            if (comparedIndexes.includes(i) && isNumeric(val)) {
                const delta = 100 * ((val - average) / Math.abs(average));
                const bg = getColorFromPercentage(delta)
                const finalValue = parseFloat(val).toLocaleString();
                return <td key={i} className='test' style={{ backgroundColor: bg }}>{finalValue}</td>
            } else {
                return <td key={i}>{val || 0}</td>
            }
        })
    }

    function openExpand(index) {
        const invalidIndex = (selectedDim.length);
        if (index > invalidIndex) setExpand(index);
        if (expand === index) setExpand(null);
    }

    function renderBodyRow(value) {
        return expand ? renderExpanded(value) : value.map((val, i) => <td key={i}>{val}</td>)
    }

    function renderTable() {
        // if expand is active, get number of colspan (timeframe)
        const colspan = JSONRawData?.reply.time || null
        let filteredValues = tableValues;
        if (processedNameFilter) {
            filteredValues = filteredValues.filter(a => a[1].toLowerCase().includes(processedNameFilter))
        }

        const rawMetrics = metrics.map(a => a.content || a)

        function getRowClass(value, i) {
            let cl = '';
            if (value[0] === 'Totals') cl = 'table-info fw-bold';
            return cl;
        }
        return (
            <div>
                <div className="input-group mb-3">
                    <input type="text" className="form-control" placeholder="Filter" value={processedNameFilter} onChange={(e) => setProcessedNameFilter(e.target.value)} />
                </div>

                Sort by
                {metrics && metrics.length ? baseSelect({
                    defaultValue: null,
                    options: rawMetrics,
                    onChange: (d) => {
                        afterJSONProcessing(JSONRawData, d.label);
                    },
                }) : null}

                <table className='table tbl table-striped table-bordered'>
                    <thead>
                        <tr>{tableRows.map((rows, index) => <th className='header table-warning' onClick={() => openExpand(index)} colSpan={(expand && index === expand) ? colspan.length + 1 : 1} key={index}>{rows?.content ? rows.content : rows}</th>)}</tr>
                        {(expand && colspan) ? <tr>{tableRows.map((a, i) => i === expand ? colspan.map(b => <th style={{ fontSize: '12px' }}>{b.substring(0, 10)}</th>) : <th></th>)}</tr> : null}
                    </thead>
                    <tbody>
                        {filteredValues.map((value, i) => <tr key={i} className={getRowClass(value, i)}>
                            {renderBodyRow(value)}
                        </tr>)}
                    </tbody>
                </table>
            </div>
        );
    }

    return (
        <div style={{ width: '90%', margin: 'auto' }}>
            <div>
                <Toggle
                    title="Extended mode"
                    onChange={() => setExtendedMode(!extendedMode)}
                />
                <div className="row align-items-start" style={{ display: 'inline-flex', margin: '2%', width: '94%' }}>
                    <div className={extendedMode ? "col-9 extendedMode" : "col-9"}>
                        {dimensions && dimensions.length ? baseSelect({
                            title: 'Group By',
                            extendedMode,
                            defaultValue: 1,
                            options: dimensions,
                            isMulti: true,
                            onChange: (d) => {
                                let result = d.map(({ value }) => value)
                                result.toString();
                                setSelectedDim(result)
                            },
                        }) : null}
                        {kpis && kpis.length ? baseSelect({
                            title: 'Metrics',
                            extendedMode,
                            defaultValue: kpis[0],
                            options: kpis,
                            isMulti: true,
                            onChange: (d) => {
                                const onlyUnique = (value, index, array) => array.indexOf(value) === index;
                                let resultRaw = [];
                                d.forEach(di => {
                                    di.value.statsType.forEach(st => {
                                        resultRaw.push(st);
                                    })
                                })
                                let result = resultRaw.filter(onlyUnique)
                                setSelectedSource(result);
                            },
                        }) : null}

                        <div>
                            <h5>Filters</h5>
                            {dimensions && dimensions.map(dim => (
                                <div className="form-check" key={dim}>
                                    <Checkbox
                                        label={dim}
                                        value={dim}
                                        array={filteredDim}
                                        onChange={() => AddFilter(dim)}
                                        disabled={dim === 'appVersionId'}
                                    />
                                    <div>
                                        <div className="card-body">
                                            {load(dim)}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='col-3'>
                        <div>
                            <h5 htmlFor="start">From-To</h5>
                            <input style={{ float: 'left' }} type="date" id="start" name="trip-start" value={startDate} min="2018-01-01" max={today} onChange={(e) => setStartDate(new Date(e.target.value).toLocaleDateString('en-CA'))} />
                            <input type="date" id="end" name="trip-start" value={endDate} min="2018-01-01" max={today} onChange={(e) => setEndDate(new Date(e.target.value).toLocaleDateString('en-CA'))} />
                        </div>
                        <div className="col" style={{ display: "grid", marginTop: '4%' }}>
                            <Checkbox
                                label="Advance"
                                value={showAdvance}
                                onChange={() => setShowAdvance(!showAdvance)}
                            />
                            <Checkbox
                                label="BD accounts(14m.o)"
                                value={getBD}
                                onChange={() => {
                                    setGetBD(!getBD)
                                    setSelectedSource(['revenues', 'reporting'])
                                }}
                            />
                            <Checkbox
                                label="Show IDs"
                                value={showId}
                                onChange={() => setShowId(!showId)}
                            />
                            <Checkbox
                                label="API Query"
                                value={showQuery}
                                onChange={() => setShowQuery(!showQuery)}
                            />
                            <Checkbox
                                label="Statistics API"
                                value={statisticsApi}
                                onChange={() => setStatisticsApi(!statisticsApi)}
                            />
                        </div>
                    </div>


                </div>
                {showAdvance && <div className="row align-items-start">
                    {statisticsApi && <div className="col">
                        <h5>Stats Source</h5>
                        <div className="form-check">
                            <Checkbox
                                label="revenue"
                                value="revenue"
                                onChange={() => setSelectedSource([{ content: "revenue", types: ['revenues'] }])}
                            />
                        </div>
                        <div className="form-check">
                            <Checkbox
                                label="report"
                                value="report"
                                onChange={() => setSelectedSource([{ content: "report", types: ['report'] }])}
                            />
                        </div>
                        <div className="form-check">
                            <Checkbox
                                label="track (user)"
                                value="track"
                                onChange={() => setSelectedSource([{ content: "track", types: ['track'] }])}
                            />
                        </div>
                    </div>}
                    <div className="col" style={{ display: 'inline-grid' }}>
                        <h5>Others</h5>
                        <Checkbox
                            label="Set Date as Columns"
                            value={dateAsColumns}
                            onChange={() => setDateAsColumns(!dateAsColumns)}
                        />
                        <Checkbox
                            label="JSON format"
                            sublabel="(/statistics)"
                            value={JSONFormat}
                            onChange={() => {
                                setJSONFormat(!JSONFormat)
                            }}
                        />
                        ClusterId: <input type="number" style={{ width: '20%' }} className="form-control" id="usr" onChange={(e) => setCluster(e.target.value)} />

                    </div>
                </div>}
                {showQuery && <div className="form-group">
                    <label htmlFor="usr">Query:</label>
                    <br />
                    {statisticsApi ? <cite>* e.g. https://api.aatkit.com/statistics/v1-dev?statistics=track&from=2024-10-01&to=2024-10-01&visibleAccounts=2030&selector=platformId,eventDay&appId=9242&clusterId=0</cite> : null}
                    <div className="row" style={{ paddingTop: '1%' }}>
                        {queryStringToObject(apiQuery)}
                    </div>
                </div>}
                <div style={{ margin: '2% 0' }}>
                    <button type="button" className="btn btn-primary" disabled={runDisabled} onClick={fetchStats}>Run</button>
                    {tableRows && <button type="button" style={{ margin: '1%' }} className="btn btn-secondary" onClick={() => csvLink.current.link.click()}>Download</button>}
                </div>
                {totalsInfo ? <div style={{ float: 'right' }}>
                    <span ><b>Total results:</b> {totalsInfo.totals - 1} </span>
                </div> : null}
                {parsedData && <CSVLink
                    data={parsedData}
                    filename='report.csv'
                    className='hidden'
                    ref={csvLink}
                    target='_blank'
                />}
                {processData && tableRows.length > 0 && <Checkbox
                    label="Show MCM/GAM"
                    value={showMCM}
                    onChange={() => setShowMCM(!showMCM)}
                />}
                {processData && tableRows.length > 0 && <Checkbox
                    label="Just Totals"
                    value={justTotals}
                    onChange={() => setJustTotals(!justTotals)}
                />}
                {tableRows && tableRows.length && !loading && !processData ? renderTable() : null}
                {statisticsApi && JSONRaw && <pre style={{ margin: '2%', background: 'whitesmoke', padding: '1%' }}>{JSON.stringify(JSONRaw, null, 4)}</pre>}
                {loading ? <Loader /> : null}
            </div>
        </div>
    )
};

const Wrapper = () => {
    return (<SupplyPages />);
}

export default Wrapper