import React, { useState, useEffect } from 'react';
import { requestWithAuth, post } from '../fetch.js';
import { baseSelect, queryStringToObject, Toggle, CheckboxSimple } from '../helpers.js';
import Loader from "../components/Loader.js";
import { AuthLoginModal, AuthChecker } from '../auth.js';
import C from '../config.js'

const today = new Date().toLocaleDateString('en-CA');
const sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toLocaleDateString('en-CA');

const granularityMapper = [
    { label: 'publisher', value: "accountId" },
    { label: 'app', value: 'appId' },
]

const findObjectsByProperty = (arr, property, searchString) => {
    return arr.filter(obj => obj[property] && obj[property].toString().toLowerCase().includes(searchString.toString().toLowerCase()));
};

const relativeDifference = (a, b) => {
    if (a === b) return 0; // If numbers are identical, the relative difference is 0
    const numerator = Math.abs(a - b);
    const denominator = Math.max(Math.abs(a), Math.abs(b));
    return (numerator / denominator) * 100; // Relative difference as a percentage
}

const groupByProperty = (arr, property) => {
    return arr.reduce((acc, obj) => {
        const key = obj[property];
        acc[key] = acc[key] || [];
        acc[key].push(obj);
        return acc;
    }, {});
};

const sortByDate = (arr, property, order = "asc") => {
    return arr.sort((a, b) => {
        const dateA = new Date(a[property]);
        const dateB = new Date(b[property]);

        return order === "asc" ? dateA - dateB : dateB - dateA;
    });
};

const mergeByDate = (arr) => {
    return arr.reduce((acc, obj) => {
        const { date, ...rest } = obj;

        if (!acc[date]) {
            acc[date] = { date, ...rest };
        } else {
            // Customize merging logic here:
            Object.keys(rest).forEach(key => {
                if (typeof rest[key] === "number") {
                    acc[date][key] = (acc[date][key] || 0) + rest[key]; // Sum numbers
                } else if (Array.isArray(rest[key])) {
                    acc[date][key] = (acc[date][key] || []).concat(rest[key]); // Merge arrays
                } else {
                    acc[date][key] = rest[key]; // Default: Override with latest value
                }
            });
        }

        return acc;
    }, {});
};


const BluePages = (props) => {
    const [demandApiQuery, setDemandApiQuery] = useState(null);
    const [supplyApiQuery, setSupplyApiQuery] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState([]);
    const [startDate, setStartDate] = useState(sevenDaysAgo);
    const [endDate, setEndDate] = useState(today);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [tableValues, setTableValues] = useState([]);
    const [processData, setProcessData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showQuery, setShowQuery] = useState(false);
    const [lastFetched, setLastFetched] = useState(null);
    const [granularity, setGranularity] = useState('accountId');
    const [extendedMode, setExtendedMode] = useState(false)
    const [totalsInfo, setTotalsInfo] = useState(null)
    const [supplySelectedMetric, setSupplySelectedMetric] = useState(null);
    const [demandSelectedMetric, setDemandSelectedMetric] = useState(null);
    const [demandMetrics, setDemandMetrics] = useState(null)
    const [supplyMetrics, setSupplyMetrics] = useState(null)
    const [supplyOriginalData, setSupplyOriginalData] = useState(null)
    const [demandOriginalData, setDemandOriginalData] = useState(null)
    const [timeFrame, setTimeFrame] = useState(null)
    const [noRevenues, setNoRevenues] = useState(false);
    const [threshold, setThreshold] = useState(0);

    const token = props.user?.token;

    async function fetchStats() {
        setSupplySelectedMetric(null)
        setDemandSelectedMetric(null)
        setTableValues(null)
        setTableHeaders(null)
        setLoading(true);
        setLastFetched(new Date());
        const nkMapper = await post(C.projects.core.api + '/reports/dashboardStats', { query: '/network-keys?networkId=' + C.graviteRtbId + ',' + C.googleBiddingId });
        // demand request
        const demandResult = await requestWithAuth('reports/demandStats', 'POST', { reportUrl: demandApiQuery }, 'google');
        // supply request
        const supplyResult = await post(C.projects.core.api + '/reports/dashboardStats', { query: supplyApiQuery });
        postProcessData(demandResult, nkMapper, supplyResult)
    }

    useEffect(() => {
        createQuery();
    }, [token, startDate, endDate, granularity, selectedFilter, noRevenues])

    function postProcessData(demand, mapper, supply) {
        // demand
        const demandData = demand.data;
        if (!mapper.length) return;
        const demandMe = Object.keys(demandData[0])
        demandData.forEach(d => {
            // let ex = nk.find(f => f.networkKey.includes(d.domain));
            let ex = findObjectsByProperty(mapper, "networkKey", d.domain)
            if (ex && ex.length) {
                d.appId = ex[0].appId;
                d.appName = ex[0].appName;
                d.accountId = ex[0].accountId;
                d.accountName = ex[0].label.split('_')[0]
            } else {
                console.error('Error', d.domain)
            }
        })

        // supply
        setSupplyMetrics(supply.request.metric)
        const supplyData = supply.reply.data.series;
        comparedByIndex({
            supplyData,
            supplyMetricName: supply.request.query.metric[0].content,
            demandData,
            demandMetricName: 'bid_requests',
            timeFrame: supply.reply.time,
            supplyMetrics: supply.request.query.metric,
            demandMetrics: demandMe
        })
    }

    function comparedByIndex({ supplyData, supplyMetricName, demandData, demandMetricName, timeFrame, supplyMetrics, demandMetrics }) {
        if (!timeFrame) return;
        const newHeaders = [...['name', granularity], ...timeFrame];
        const newValues = []
        // demand
        // group by account
        const groupedByAcc = groupByProperty(demandData, granularity);
        Object.keys(groupedByAcc).forEach(g => {
            const row = []
            if (granularity === 'appId') {
                row.push(groupedByAcc[g][0].appName, groupedByAcc[g][0].appId)
            }
            if (granularity === 'accountId') {
                row.push(groupedByAcc[g][0].accountName, groupedByAcc[g][0].accountId)
            }
            // sort by date
            const sortedByTime = sortByDate(groupedByAcc[g], 'date');
            const mergedByDate = mergeByDate(sortedByTime);
            Object.keys(mergedByDate).forEach(sbt => {
                row.push(mergedByDate[sbt][demandMetricName])
            })
            if (row.length < newHeaders.length) {
                for (let i = row.length; i < newHeaders.length; i++) {
                    row.push('N/A')
                }
            }
            newValues.push(row);
        })
        const supplyMetricIndex = supplyMetrics.findIndex(f => f.content === supplyMetricName)
        supplyData.forEach(sd => {
            // look for Id
            const indexCombined = newValues.findIndex(nv => nv[1] === sd.id);
            if (indexCombined > -1) {
                sd.stats[supplyMetricIndex].forEach((sds, i) => {
                    const demandValue = newValues[indexCombined][i + 2];
                    const supplyValue = sds
                    // two start indexes are for Name and Id
                    // const resformatted = parseInt(supplyValue)
                    const delta = relativeDifference(demandValue, supplyValue)
                    newValues[indexCombined][i + 2] = `<b>${demandValue} </b> (${supplyValue}) [<i>${delta}%<i>]`
                })
            }
        })

        // sort by name
        const sortByFirstElement = (arr) => {
            return arr.sort((a, b) => {
                if (a[0] && b[0]) {
                    return a[0].toString().localeCompare(b[0]);
                } else {
                    return a[0]
                }
            })
          };

        setSupplyOriginalData(supplyData);
        setDemandOriginalData(demandData)
        setTimeFrame(timeFrame)
        setSupplySelectedMetric(supplyMetricName)
        setDemandSelectedMetric(demandMetricName)
        setDemandMetrics(demandMetrics)
        setSupplyMetrics(supplyMetrics)
        setTableHeaders(newHeaders);
        setTableValues(sortByFirstElement(newValues));
        setLoading(false);
    }

    function createQuery() {
        // demand
        let demandQuery = token + "/adx-report?attribute[]=domain&metric[]=ssp_requests&metric[]=bid_requests&metric[]=bid_responses&metric[]=dsp_wins&metric[]=ssp_wins&metric[]=impressions&metric[]=clicks&metric[]=ssp_revenue&metric[]=ssp_ecpm&metric[]=avg_ssp_bid_price&metric[]=avg_ssp_bid_floor&metric[]=avg_dsp_bid_price&metric[]=avg_dsp_bid_floor&metric[]=dsp_spend&metric[]=dsp_ecpm&metric[]=profit&metric[]=bid_rate";
        demandQuery += '&from=' + startDate + '&to=' + endDate;
        demandQuery += '&day_group=day';
        demandQuery += '&limit=10000'
        setDemandApiQuery(demandQuery);

        // supply
        const supplyQuery = "/statistics?selector=" + granularity + ",eventDay&dateAsColumns=true&networkId=" + C.graviteRtbId + ',' + C.googleBiddingId + "&from=" + startDate + "&to=" + endDate + "&revenues=" + noRevenues + "&user=false&reporting=true";
        setSupplyApiQuery(supplyQuery)
    }
    const extractNumberBeforePercent = (str) => {
        const match = str.match(/(\d+(\.\d+)?)%/);
        return match ? match[1] : null;
    };

    function getBgColor(val) {
        if (val && val.toString().includes('[<i>') && threshold && threshold > 0) {
            const delta = parseInt(extractNumberBeforePercent(val));
            return (delta > threshold) ? 'orangered' : 'greenyellow';
        }
        return 'inherit'
    }

    return (
        <div style={{ width: '90%', margin: 'auto' }}>
            <div>
                {AuthChecker('both')}
                <Toggle
                    title="Extended mode"
                    onChange={() => setExtendedMode(!extendedMode)}
                />
                <div className="row align-items-start" style={{ display: 'inline-flex', margin: '2%', width: '94%' }}>
                    <div className={extendedMode ? "col-9 extendedMode" : "col-9"}>

                        {baseSelect({
                            title: 'Granularity',
                            extendedMode,
                            defaultValue: granularityMapper[0],
                            options: granularityMapper,
                            onChange: (e) => setGranularity(e.value)
                        })}
                    </div>

                    <div className='col-3'>
                        <h5 htmlFor="start">From-To</h5>
                        <input style={{ float: 'left' }} type="date" id="start" name="trip-start" value={startDate} min="2018-01-01" max={today} onChange={(e) => setStartDate(new Date(e.target.value).toLocaleDateString('en-CA'))} />
                        <input type="date" id="end" name="trip-start" value={endDate} min="2018-01-01" max={today} onChange={(e) => setEndDate(new Date(e.target.value).toLocaleDateString('en-CA'))} />
                    </div>
                    <CheckboxSimple
                        label={'Include Revenues (slower, many metrics)'}
                        onChange={() => {
                            setNoRevenues(!noRevenues)
                        }}
                    />
                </div>
                <br />
                {showQuery && <div className="form-group">
                    {queryStringToObject(C.projects.rtbApi.api + '/' + demandApiQuery)}
                </div>}
                <button type="button" style={{ marginBottom: '50px', width: '100%' }} className="btn btn-primary" onClick={fetchStats}>Run</button>
                <br />
                {lastFetched ? <div style={{ float: 'right' }}>
                    {!processData && totalsInfo && totalsInfo ? <span ><b>Total results:</b> {totalsInfo.totals} (pages: {totalsInfo.totalPages})</span> : null}
                    <button type="button" onClick={() => fetchStats()} className="btn btn-link"><i className="bi bi-arrow-clockwise" /> </button>
                    <span >Last sync: {lastFetched.toLocaleDateString()}: {lastFetched.toLocaleTimeString()}</span>
                </div> : null}
                {tableValues ? (<div>

                    <h5>Gravite Marketplace Demand vs Supply</h5>
                    <div style={{ display: 'inline-flex', width: '40%' }}>
                        {demandMetrics && demandMetrics.length ? baseSelect({
                            options: demandMetrics,
                            defaultValue: demandMetrics.findIndex(f => f == demandSelectedMetric),
                            onChange: (e) => {
                                comparedByIndex({
                                    supplyData: supplyOriginalData,
                                    supplyMetricName: supplySelectedMetric,
                                    demandData: demandOriginalData,
                                    demandMetricName: e.value,
                                    timeFrame: timeFrame,
                                    supplyMetrics: supplyMetrics,
                                    demandMetrics: demandMetrics
                                })
                            }
                        }) : null}
                        {supplyMetrics && supplyMetrics.length ? baseSelect({
                            options: supplyMetrics.map(f => f.content),
                            defaultValue:  supplyMetrics.map(f => f.content).findIndex(f => f == supplySelectedMetric),
                            onChange: (e) => {
                                comparedByIndex({
                                    supplyData: supplyOriginalData,
                                    supplyMetricName: e.value,
                                    demandData: demandOriginalData,
                                    demandMetricName: demandSelectedMetric,
                                    timeFrame: timeFrame,
                                    supplyMetrics: supplyMetrics,
                                    demandMetrics: demandMetrics
                                })
                            }
                        }) : null}
                    </div>

                    <br /><i>Gravite API does not offer profit as metric, this is calculated substracting NetRevenues from Revenues </i>
                    <br /><i>In oder to get data from Gravite API, we request from network Gravite Marketplace (76) & Google Bidding (77) </i>
                    <br /><i>Some accounts have a dynamic share so here will be shown as 0.00 </i>

                    <span style={{ display: 'flow', width: '20%', margin: '2% 0' }}>
                        Threshold: <input className="form-control" style={{ float: 'left' }} type="text" value={threshold} onChange={(e) => setThreshold(e.target.value)} />
                    </span>

                    <table className='table tbl table-striped table-bordered' style={{display: 'block', margin: '2% 0'}}>
                        <thead><tr>{tableHeaders.map((rows, index) => <th className='header table-warning' key={index}>{rows}</th>)}</tr></thead>
                        <tbody>{tableValues.map((value, index) => <tr key={index}>{value.map((val, i) => <td key={i} style={{ background: getBgColor(val) }} dangerouslySetInnerHTML={{ __html: val }} />)}</tr>)}</tbody>
                    </table>
                </div>) : null}
                {loading ? <Loader /> : null}

            </div>
        </div>
    )
};

const Wrapper = () => {
    return (<AuthLoginModal target='demand' style='rtb' open={true} fallback={(user, onLogout) => <BluePages user={user} onLogout={onLogout} />} />);
}

export default Wrapper