import React, { useState, useEffect } from 'react';
import { requestWithAuth, post } from '../fetch.js';
import { baseSelect } from '../helpers.js';
import Loader from "../components/Loader.js";
import { AuthLoginModal } from '../auth.js';
import C from '../config.js'
import { useCSVDownloader, usePapaParse } from 'react-papaparse';
import GMCompare from './GMCompare.js'

const today = new Date().toLocaleDateString('en-CA');
const sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toLocaleDateString('en-CA');

const granularityMapper = [
    { label: 'publisher', value: "accountId" },
    { label: 'app', value: 'appId' },
]

const findObjectsByProperty = (arr, property, searchString = '') => {
    if (searchString === '') {
        return arr.filter(obj => obj[property] && obj[property].toString().toLowerCase())
    }

    return arr.filter(obj => obj[property] && obj[property].toString().toLowerCase().includes(searchString.toString().toLowerCase()));
};

const camelCaseToSpaces = (str) => {
    return str.replace(/([a-z])([A-Z])/g, "$1 $2");
};

const DataTable = ({ data, onSort, onChangeShare, totals }) => {
    if (!data || data.length === 0) return <p>No data available</p>;

    const { CSVDownloader, Type } = useCSVDownloader();
    const { jsonToCSV } = usePapaParse();

    const columns = Object.keys(data[0]);

    const csvString = jsonToCSV(data);

    function naturalizeString(str) {
        return camelCaseToSpaces(str).replaceAll('_', ' ');
    }
``
    function renderShare(row, col) {
        if (row[col] == 0 || (typeof row[col] === 'string' && row[col].includes('_'))) {
            return <input type="number" defaultValue="10" max={100} style={{ width: '60px' }} onChange={(e) => onChangeShare(row.id, e.target.value, col, col.toLowerCase().includes('mediation'))} />;
        } else {
            return row[col];
        }
    }

    return (
        <>
            <CSVDownloader data={csvString} style={{ margin: '1% 0', display: 'block' }} className="btn btn-dark" filename={'financeReport'} type={Type.CSV}>CSV</CSVDownloader>

            <table className="table tbl table-striped table-bordered">
                <thead className='header table-warning'>
                    <tr className="bg-gray-200">
                        {columns.map((col) => (
                            <th
                                key={col}
                                style={{
                                    cursor: 'pointer',
                                    backgroundColor: getBackgroundColor(col),
                                    color: getFontColor(col)
                                }}
                                className="border border-gray-400 px-4 py-2"
                                onClick={() => onSort(col)}
                            >
                                {naturalizeString(col)}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, rowIndex) => (
                        <tr key={rowIndex} className="odd:bg-white even:bg-gray-100">
                            {columns.map((col) => (
                                <td
                                    key={col}
                                    className="border border-gray-400 px-4 py-2"
                                    style={{
                                        backgroundColor: getBackgroundColor(col),
                                        color: getFontColor(col)
                                    }}
                                >
                                    {col === 'supplyShare' ? renderShare(row, col) : row[col]}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

const getBackgroundColor = (col) => {
    const lowerCol = col.toLowerCase();

    if (lowerCol.includes('supplyshare')) {
        return 'rgba(255, 105, 180, 0.5)';
    }
    if (lowerCol.includes('mediation') && lowerCol.includes('supply')) {
        return 'rgba(246, 177, 63, 1)';
    }
    if (lowerCol.includes('supply') && !lowerCol.includes('gmprofit')) {
        return 'rgba(133, 47, 208, 0.5)';
    }
    if (lowerCol.includes('gmprofit') || lowerCol.includes('totalprofit')) {
        return 'rgba(134, 187, 119, 0.5)';
    }
    return 'inherit';
};


const getFontColor = (col) => {
    return col.toLowerCase().includes('supply') && !col.toLowerCase().includes('gmprofit') ? 'white' : 'black';
};


const groupByProperty = (arr, property) => {
    return arr.reduce((acc, obj) => {
        const key = obj[property];
        acc[key] = acc[key] || [];
        acc[key].push(obj)
        return acc;
    }, {});
};

let sortOrder = {};

const sortByProperty = (arr, key) => {
    sortOrder[key] = !sortOrder[key];

    const totalRowIndex = arr.findIndex(row => row.id === "total");
    const totalRow = totalRowIndex !== -1 ? arr.splice(totalRowIndex, 1)[0] : null;

    arr.sort((a, b) => {
        if (a[key] === undefined || b[key] === undefined) return 0;

        let valueA = a[key];
        let valueB = b[key];

        const isNumericA = !isNaN(parseFloat(valueA)) && isFinite(valueA);
        const isNumericB = !isNaN(parseFloat(valueB)) && isFinite(valueB);

        if (isNumericA && isNumericB) {
            valueA = Number(valueA);
            valueB = Number(valueB);
            return sortOrder[key] ? valueA - valueB : valueB - valueA;
        }

        if (typeof valueA === "string" && typeof valueB === "string") {
            return sortOrder[key] ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
        }

        return 0;
    });

    if (totalRow) arr.push(totalRow);

    return arr;
};


const GMOverview = (props) => {
    const [demandApiQuery, setDemandApiQuery] = useState(null);
    const [demandTotalsApiQuery, setDemandTotalsApiQuery] = useState(null);
    const [supplyTotalsApiQuery, setSupplyTotalsApiQuery] = useState(null);
    const [supplyMarketPlaceApiQuery, setSupplyMarketPlaceApiQuery] = useState(null);
    const [supplyNoMarketPlaceApiQuery, setSupplyNoMarketPlaceApiQuery] = useState(null);
    const [startDate, setStartDate] = useState(sevenDaysAgo);
    const [endDate, setEndDate] = useState(today);
    const [tableValues, setTableValues] = useState([]);
    const [loading, setLoading] = useState(false);
    const [lastFetched, setLastFetched] = useState(null);
    const [granularity, setGranularity] = useState('accountId');
    const [timeGran, setTimeGran] = useState('day');
    const [shares, setShares] = useState(null);
    const [networkKeyMapper, setNetworkKeyMapper] = useState(null)

    const token = props.user?.token;

    async function fetchInitialResources() {
        const resources = await requestWithAuth('reports/cacheResources?type=mapper');
        setShares(resources.shares);
        setNetworkKeyMapper(resources.gmNetworkKey)
    }

    async function fetchStats() {
        setTableValues(null);
        setLoading(true);
        setLastFetched(new Date());

        const [supplyMarketPlaceResult, supplyNoMarketPlaceResult, accounts, demandResult] = await Promise.all([
            post(C.projects.core.api + '/reports/dashboardStats', { query: supplyMarketPlaceApiQuery }),
            post(C.projects.core.api + '/reports/dashboardStats', { query: supplyNoMarketPlaceApiQuery }),
            post(C.projects.core.api + '/reports/dashboardStats', { query: '/accounts' }),
            requestWithAuth('reports/demandStats', 'POST', { reportUrl: demandApiQuery }, 'google')
        ]);

        postProcessData(demandResult, supplyMarketPlaceResult, supplyNoMarketPlaceResult, accounts);
    }

    useEffect(() => {
        fetchInitialResources();
    }, [])

    useEffect(() => {
        createQuery();
    }, [token, startDate, endDate, granularity])

    function getBillingId(accId, accounts) {
        return accounts.find(acc => acc.id == accId).billingAccountId
    }

    function postProcessData(demand, supplyMarketPlaceResult, supplyNoMarketPlaceResult, accounts) {
        // demand
        const demandData = demand.data;
        if (!networkKeyMapper || !networkKeyMapper.length) return;

        demandData.forEach(d => {
            let ex = findObjectsByProperty(networkKeyMapper, "networkKey", d.domain);
            if (ex && ex.length) {
                d.appId = ex[0].appId;
                d.appName = ex[0].appName;
                d.accountId = ex[0].accountId;
                d.accountName = ex[0].label.split('_')[0];

                if (!d.company_dsp) {
                    d.company_dsp = ex[0].company_dsp;
                }
            } else {
                console.error('Err no nk match', d);
            }
        });

        // supply
        const supplyMarketPlaceData = supplyMarketPlaceResult.reply.data.series;
        const supplyNoMarketPlaceData = supplyNoMarketPlaceResult.reply.data.series;
        const newValues = [];

        // demand - group by account
        const groupedByAcc = groupByProperty(demandData, granularity, true);
        Object.keys(groupedByAcc).forEach(g => {
            const row = {};

            if (granularity === 'appId') {
                row.name = groupedByAcc[g][0].appName;
                row.id = groupedByAcc[g][0].appId;
            }
            else if (granularity === 'accountId') {
                row.name = groupedByAcc[g][0].accountName;
                row.id = groupedByAcc[g][0].accountId;
                row.billingId = getBillingId(row.id, accounts)
            }

            let dsp_spend = 0, ssp_revenue = 0, demand_profit = 0;
            let dsp_spend_gbid = 0, ssp_revenue_gbid = 0, demand_profit_gbid = 0;
            let ssp_total_revenue = 0;
            groupedByAcc[g].forEach(item => {
                if (item.company_dsp === 'Google Bidding') {
                    dsp_spend_gbid += parseFloat(item.dsp_spend) || 0;
                    ssp_revenue_gbid += parseFloat(item.ssp_revenue) || 0;
                    demand_profit_gbid += parseFloat(item.profit) || 0;
                    ssp_total_revenue += parseFloat(item.ssp_revenue) || 0;
                } else {
                    dsp_spend += parseFloat(item.dsp_spend) || 0;
                    ssp_revenue += parseFloat(item.ssp_revenue) || 0;
                    demand_profit += parseFloat(item.profit) || 0;
                    ssp_total_revenue += parseFloat(item.ssp_revenue) || 0;
                }
            });

            row.dsp_spend = dsp_spend.toFixed(2);
            row.ssp_revenue = ssp_revenue.toFixed(2);
            row.demand_profit = demand_profit.toFixed(2);

            row.dsp_spend_gbid = dsp_spend_gbid.toFixed(2);
            row.ssp_revenue_gbid = ssp_revenue_gbid.toFixed(2);
            row.demand_profit_gbid = demand_profit_gbid.toFixed(2);

            row.ssp_total_revenue = ssp_total_revenue.toFixed(2)
            newValues.push(row);
        });

        const metricsMap = {};
        supplyMarketPlaceResult.request.query.metric.forEach((metric, index) => {
            metricsMap[metric.content] = index;
        });

        function getSupplyShare(granularity, shares, id) {
            if (granularity === "accountId") {
                const indexCombined = shares.accounts.findIndex(sacc => sacc.accountId === id);
                return indexCombined > -1 ? shares.accounts[indexCombined].share : 0;
            }

            if (granularity === "appId") {
                const indexCombined = shares.apps.findIndex(sacc => sacc.appId === id);
                return indexCombined > -1 ? shares.apps[indexCombined].share : 0;
            }

            return 0;
        }

        supplyMarketPlaceData.forEach(sd => {
            const accountId = sd.id;
            const accountName = sd.label;
            const revenue = parseFloat(sd.total[metricsMap['revenue']] || 0);
            const netRevenue = parseFloat(sd.total[metricsMap['netRevenue']] || 0);
            const impressions = parseInt(sd.total[metricsMap['impressions']] || 0);

            let indexCombined = newValues.findIndex(nv => nv.id === accountId);

            if (indexCombined === -1) {
                let newRow = {
                    id: accountId,
                    name: accountName,
                    billingId: getBillingId(accountId, accounts),
                    supplyShare: getSupplyShare(granularity, shares, accountId),
                    supplyImpressionsMarketPlace: impressions,
                    supply_revenuesMarketPlace: revenue,
                    supply_net_revenuesMarketPlace: netRevenue,
                    supplyProfitMarketPlace: (revenue - netRevenue).toFixed(2),
                    supplyECpmMarketPlace: impressions > 0 ? ((revenue / impressions) * 1000).toFixed(2) : "0.00",
                    supplyNetECpmMarketPlace: impressions > 0 ? ((netRevenue / impressions) * 1000).toFixed(2) : "0.00",
                };
                newValues.push(newRow);
            } else {
                let existingRow = newValues[indexCombined];
                existingRow.supplyShare = getSupplyShare(granularity, shares, accountId);
                existingRow.supply_revenuesMarketPlace = (existingRow.supply_revenuesMarketPlace || 0) + revenue;
                existingRow.supply_net_revenuesMarketPlace = (existingRow.supply_net_revenuesMarketPlace || 0) + netRevenue;
                existingRow.supplyProfitMarketPlace = (existingRow.supply_revenuesMarketPlace - existingRow.supply_net_revenuesMarketPlace).toFixed(2);


                existingRow.supplyImpressionsMarketPlace = (existingRow.supplyImpressionsMarketPlace || 0) + impressions;

                existingRow.supplyECpmMarketPlace = existingRow.supplyImpressionsMarketPlace > 0
                    ? ((existingRow.supply_revenuesMarketPlace / existingRow.supplyImpressionsMarketPlace) * 1000).toFixed(2)
                    : "0.00";
                existingRow.supplyNetECpmMarketPlace = existingRow.supplyImpressionsMarketPlace > 0
                    ? ((existingRow.supply_net_revenuesMarketPlace / existingRow.supplyImpressionsMarketPlace) * 1000).toFixed(2)
                    : "0.00";
            }
        });


        newValues.forEach(nv => {
            const variance = parseFloat(nv.ssp_revenue) - parseFloat(nv.supply_revenuesMarketPlace);
            let varianceSSPvsSupply = nv.ssp_revenue != 0
                ? ((variance / parseFloat(nv.ssp_revenue)) * 100).toFixed(2) + "%"
                : "N/A";

            let GMprofitSupplyAndDemand = (
                (parseFloat(nv.demand_profit) || 0) +
                (parseFloat(nv.demand_profit_gbid) || 0) +
                (parseFloat(nv.supplyProfitMarketPlace) || 0)
            ).toFixed(2);

            let entries = Object.entries(nv);

            let index = entries.findIndex(([key]) => key === "supplyProfitMarketPlace");

            if (index !== -1) {
                entries.splice(index + 1, 0, ["varianceSSPvsSupply", varianceSSPvsSupply]);
            } else {
                entries.push(["varianceSSPvsSupply", varianceSSPvsSupply]);
            }

            entries.push(["GMprofitSupplyAndDemand", GMprofitSupplyAndDemand]);

            newValues[newValues.indexOf(nv)] = Object.fromEntries(entries);

        });

        supplyNoMarketPlaceData.forEach(sd => {
            const accountId = sd.id;
            const accountName = sd.label;

            const revenue = parseFloat(sd.total[metricsMap['revenue']] || 0);
            const netRevenue = parseFloat(sd.total[metricsMap['netRevenue']] || 0);
            const impressions = parseInt(sd.total[metricsMap['impressions']] || 0);

            let indexCombined = newValues.findIndex(nv => nv.id === accountId);

            if (indexCombined === -1) {
                //means data only on supply side
                let newRow = {
                    id: accountId,
                    name: accountName,
                    billingId: getBillingId(accountId, accounts),
                    supplyShare: getSupplyShare(granularity, shares, accountId),
                    supplyImpressionsMediation: impressions,
                    supply_revenuesMediation: revenue,
                    supply_net_revenuesMediation: netRevenue,
                    supplyProfitMediation: (revenue - netRevenue).toFixed(2),
                    supplyECpmMediation: impressions > 0 ? ((revenue / impressions) * 1000).toFixed(2) : "0.00",
                    supplyNetECpmMediation: impressions > 0 ? ((netRevenue / impressions) * 1000).toFixed(2) : "0.00",
                    dsp_spend: "0.00",
                    dsp_revenue: "0.00",
                    demand_profit: "0.00",
                    dsp_spend_gbid: "0.00",
                    ssp_revenue_gbid: "0.00",
                    ssp_revenue: "0.00",
                    demand_profit_gbid: "0.00",
                    ssp_total_revenue: "0.00",
                    totalProfitGraviteMarketPlace: "0.00",
                    totalProfitGraviteMediation: "0.00",
                    GMprofitSupplyAndDemand: "0.00",
                    supplyImpressionsMarketPlace: parseInt(0),
                    supply_revenuesMarketPlace: "0.00",
                    supply_net_revenuesMarketPlace: "0.00",
                    supplyECpmMarketPlace: "0.00",
                    supplyNetECpmMarketPlace: "0.00",
                    supplyProfitMarketPlace: "0.00"
                };
                newValues.push(newRow);
            } else {
                let existingRow = newValues[indexCombined];
                existingRow.supplyShare = getSupplyShare(granularity, shares, accountId);
                existingRow.supply_revenuesMediation = (existingRow.supply_revenuesMediation || 0) + revenue;
                existingRow.supply_net_revenuesMediation = (existingRow.supply_net_revenuesMediation || 0) + netRevenue;
                existingRow.supplyProfitMediation = (existingRow.supply_revenuesMediation - existingRow.supply_net_revenuesMediation).toFixed(2);
                existingRow.supplyImpressionsMediation = (existingRow.supplyImpressionsMediation || 0) + impressions;
                existingRow.supplyECpmMediation = existingRow.supplyImpressionsMediation > 0
                    ? ((existingRow.supply_revenuesMediation / existingRow.supplyImpressionsMediation) * 1000).toFixed(2)
                    : "0.00";
                existingRow.supplyNetECpmMediation = existingRow.supplyImpressionsMediation > 0
                    ? ((existingRow.supply_net_revenuesMediation / existingRow.supplyImpressionsMediation) * 1000).toFixed(2)
                    : "0.00";
            }
        });


        newValues.forEach(nv => {
            nv.totalProfit =
                ((parseFloat(nv.GMprofitSupplyAndDemand) || 0) +
                    (parseFloat(nv.supplyProfitMediation) || 0)).toFixed(2);
        });


        const totalRow = {
            name: "TOTAL",
            id: "total",
            dsp_spend: newValues.reduce((acc, nv) => acc + (isNaN(parseFloat(nv.dsp_spend)) ? 0 : parseFloat(nv.dsp_spend)), 0).toFixed(2),
            dsp_revenue: newValues.reduce((acc, nv) => acc + (isNaN(parseFloat(nv.dsp_revenue)) ? 0 : parseFloat(nv.dsp_revenue)), 0).toFixed(2),
            demand_profit: newValues.reduce((acc, nv) => acc + (isNaN(parseFloat(nv.demand_profit)) ? 0 : parseFloat(nv.demand_profit)), 0).toFixed(2),
            dsp_spend_gbid: newValues.reduce((acc, nv) => acc + (isNaN(parseFloat(nv.dsp_spend_gbid)) ? 0 : parseFloat(nv.dsp_spend_gbid)), 0).toFixed(2),
            ssp_revenue_gbid: newValues.reduce((acc, nv) => acc + (isNaN(parseFloat(nv.ssp_revenue_gbid)) ? 0 : parseFloat(nv.ssp_revenue_gbid)), 0).toFixed(2),
            ssp_revenue: newValues.reduce((acc, nv) => acc + (isNaN(parseFloat(nv.ssp_revenue)) ? 0 : parseFloat(nv.ssp_revenue)), 0).toFixed(2),
            demand_profit_gbid: newValues.reduce((acc, nv) => acc + (isNaN(parseFloat(nv.demand_profit_gbid)) ? 0 : parseFloat(nv.demand_profit_gbid)), 0).toFixed(2),
            ssp_total_revenue: newValues.reduce((acc, nv) => acc + (isNaN(parseFloat(nv.ssp_total_revenue)) ? 0 : parseFloat(nv.ssp_total_revenue)), 0).toFixed(2),
            supply_revenuesMarketPlace: newValues.reduce((acc, nv) => acc + (isNaN(parseFloat(nv.supply_revenuesMarketPlace)) ? 0 : parseFloat(nv.supply_revenuesMarketPlace)), 0).toFixed(2),
            supply_net_revenuesMarketPlace: newValues.reduce((acc, nv) => acc + (isNaN(parseFloat(nv.supply_net_revenuesMarketPlace)) ? 0 : parseFloat(nv.supply_net_revenuesMarketPlace)), 0).toFixed(2),
            supplyProfitMarketPlace: newValues.reduce((acc, nv) => acc + (isNaN(parseFloat(nv.supplyProfitMarketPlace)) ? 0 : parseFloat(nv.supplyProfitMarketPlace)), 0).toFixed(2),
            totalProfitGraviteMarketPlace: newValues.reduce((acc, nv) => acc + (isNaN(parseFloat(nv.totalProfitGraviteMarketPlace)) ? 0 : parseFloat(nv.totalProfitGraviteMarketPlace)), 0).toFixed(2),
            supplyImpressionsMarketPlace: newValues.reduce((acc, nv) => acc + (isNaN(parseInt(nv.supplyImpressionsMarketPlace)) ? 0 : parseInt(nv.supplyImpressionsMarketPlace)), 0),
            supply_revenuesMediation: newValues.reduce((acc, nv) => acc + (isNaN(parseFloat(nv.supply_revenuesMediation)) ? 0 : parseFloat(nv.supply_revenuesMediation)), 0).toFixed(2),
            supply_net_revenuesMediation: newValues.reduce((acc, nv) => acc + (isNaN(parseFloat(nv.supply_net_revenuesMediation)) ? 0 : parseFloat(nv.supply_net_revenuesMediation)), 0).toFixed(2),
            supplyProfitMediation: newValues.reduce((acc, nv) => acc + (isNaN(parseFloat(nv.supplyProfitMediation)) ? 0 : parseFloat(nv.supplyProfitMediation)), 0).toFixed(2),
            totalProfitGraviteMediation: newValues.reduce((acc, nv) => acc + (isNaN(parseFloat(nv.totalProfitGraviteMediation)) ? 0 : parseFloat(nv.totalProfitGraviteMediation)), 0).toFixed(2),
            supplyImpressionsMediation: newValues.reduce((acc, nv) => acc + (isNaN(parseInt(nv.supplyImpressionsMediation)) ? 0 : parseInt(nv.supplyImpressionsMediation)), 0),
            GMprofitSupplyAndDemand: newValues.reduce((acc, nv) => acc + (isNaN(parseFloat(nv.GMprofitSupplyAndDemand)) ? 0 : parseFloat(nv.GMprofitSupplyAndDemand)), 0).toFixed(2),
        };

        const totalVariance = totalRow.ssp_total_revenue - totalRow.supply_revenuesMarketPlace;
        totalRow.varianceSSPvsSupply = totalRow.ssp_total_revenue !== 0 && !isNaN(totalRow.ssp_total_revenue) && !isNaN(totalVariance)
            ? ((totalVariance / totalRow.ssp_total_revenue) * 100).toFixed(2) + "%"
            : "N/A";

        const totalImpressionsMarketPlace = parseInt(totalRow.supplyImpressionsMarketPlace);
        const totalSupplyRevenuesMarketPlace = parseFloat(totalRow.supply_revenuesMarketPlace);
        const totalSupplyNetRevenuesMarketPlace = parseFloat(totalRow.supply_net_revenuesMarketPlace);

        totalRow.supplyECpmMarketPlace = totalImpressionsMarketPlace > 0 ? (totalSupplyRevenuesMarketPlace / totalImpressionsMarketPlace * 1000).toFixed(2) : "0.00";
        totalRow.supplyNetECpmMarketPlace = totalImpressionsMarketPlace > 0 ? (totalSupplyNetRevenuesMarketPlace / totalImpressionsMarketPlace * 1000).toFixed(2) : "0.00";

        const totalImpressionsMediation = parseInt(totalRow.supplyImpressionsMediation);
        const totalSupplyRevenuesMediation = parseFloat(totalRow.supply_revenuesMediation);
        const totalSupplyNetRevenuesMediation = parseFloat(totalRow.supply_net_revenuesMediation);

        totalRow.supplyECpmMediation = totalImpressionsMediation > 0 ? (totalSupplyRevenuesMediation / totalImpressionsMediation * 1000).toFixed(2) : "0.00";
        totalRow.supplyNetECpmMediation = totalImpressionsMediation > 0 ? (totalSupplyNetRevenuesMediation / totalImpressionsMediation * 1000).toFixed(2) : "0.00";

        totalRow.totalProfit =
            ((parseFloat(totalRow.GMprofitSupplyAndDemand) || 0) +
                (parseFloat(totalRow.supplyProfitMediation) || 0)).toFixed(2);

        const mediationTypes = [...new Set(supplyMarketPlaceData.map(sd => sd.label[0]))];

        /*mediationTypes.forEach(type => {
            let columnName = `supplyRevenues${type}`;
            totalRow[columnName] = newValues.reduce((acc, nv) => acc + (isNaN(parseFloat(nv[columnName])) ? 0 : parseFloat(nv[columnName])), 0).toFixed(2);

        });
        */
        newValues.push(totalRow);

        setTableValues(newValues);
        setLoading(false);
    }



    const capitalize = (str, lower = false) =>
        (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
    ;

    function createQuery() {
        // demand
        let demandQuery = token + "/adx-report?metric[0]=ssp_revenue&metric[1]=dsp_spend&metric[3]=profit&attribute[0]=domain&attribute[1]=company_dsp";
        demandQuery += '&from=' + startDate + '&to=' + endDate;
        demandQuery += '&day_group=total' // + timeGran.toLowerCase();
        demandQuery += '&limit=10000'

        setDemandApiQuery(demandQuery);

        // demand Totals
        let demandTotalsQuery = token + "/adx-report?metric[]=profit";
        demandTotalsQuery += '&from=' + startDate + '&to=' + endDate;
        demandTotalsQuery += '&day_group=total' // + timeGran.toLowerCase();
        setDemandTotalsApiQuery(demandTotalsQuery);

        //supply Market place
        let supplyMarketPlaceApiQuery = "/statistics?";
        supplyMarketPlaceApiQuery += "selector=" + granularity + ",event" + capitalize(timeGran);
        supplyMarketPlaceApiQuery += "&dateAsColumns=true&networkId=" + C.graviteRtbId;
        supplyMarketPlaceApiQuery += "&from=" + startDate + "&to=" + endDate + "&metric[]=impressions&metric[]=revenue&metric[]=netRevenue&metric[]=aCpm&targetCurrency=USD";
        setSupplyMarketPlaceApiQuery(supplyMarketPlaceApiQuery)

        //supply Market place
        let supplyNoMarketPlaceApiQuery = "/statistics?";
        supplyNoMarketPlaceApiQuery += "selector=" + granularity + ",event" + capitalize(timeGran);
        supplyNoMarketPlaceApiQuery += "&dateAsColumns=true&networkId=" + C.graviteRtbId + '&negativeFilter=networkId';
        supplyNoMarketPlaceApiQuery += "&from=" + startDate + "&to=" + endDate + "&metric[]=impressions&metric[]=revenue&metric[]=netRevenue&metric[]=aCpm&targetCurrency=USD";
        setSupplyNoMarketPlaceApiQuery(supplyNoMarketPlaceApiQuery)

        // supply totals
        let supplyTotalsQuery = '/dashboard/revenues?'
        supplyTotalsQuery += "from=" + startDate + "&to=" + endDate + "&type=revenues&targetCurrency=USD";
        setSupplyTotalsApiQuery(supplyTotalsQuery)
    }

    function changeShare(id, newValue = 10, columnName) {
        const index = tableValues.findIndex(nv => nv.id === id);
        if (index > -1) {
            const newData = [...tableValues];

            const validNewValue = (newValue === '' || isNaN(newValue)) ? 0 : newValue;

            newData[index][columnName] = '_' + validNewValue;

            const netRevMarketPlace = (newData[index].supply_net_revenuesMarketPlace * parseInt(validNewValue) / 100).toFixed(2);
            const netRevMediation = (newData[index].supply_net_revenuesMediation * parseInt(validNewValue) / 100).toFixed(2);

            newData[index].supplyProfitMarketPlace = netRevMarketPlace;
            newData[index].supplyProfitMediation = netRevMediation;

            const demandProfit = parseFloat(newData[index].demand_profit) || 0;
            newData[index].GMprofitSupplyAndDemand = (demandProfit + parseFloat(netRevMarketPlace)).toFixed(2);

            const totalIndex = newData.findIndex(nv => nv.id === 'total');
            if (totalIndex > -1) {
                newData[totalIndex].GMprofitSupplyAndDemand = newData.reduce((acc, nv) => {
                    if (nv.id !== 'total') {
                        return acc + (isNaN(parseFloat(nv.GMprofitSupplyAndDemand)) ? 0 : parseFloat(nv.GMprofitSupplyAndDemand));
                    }
                    return acc;
                }, 0).toFixed(2);

                newData[totalIndex].supplyProfitMediation = newData.reduce((acc, nv) => {
                    if (nv.id !== 'total') {
                        return acc + (isNaN(parseFloat(nv.supplyProfitMediation)) ? 0 : parseFloat(nv.supplyProfitMediation));
                    }
                    return acc;
                }, 0).toFixed(2);
            }

            setTableValues(newData);

            if (validNewValue === 10) {
                const inputElement = document.getElementById(`share-input-${id}`);
                if (inputElement) {
                    inputElement.value = 10;
                }
            }
        }
    }

    function sorting(propSelected) {
        const toSort = [...tableValues]
        setLoading(true);
        sortByProperty(toSort, propSelected);
        setTableValues(toSort);
        setLoading(false);
    }

    return (
        <div style={{ width: '90%', margin: 'auto' }}>
            <div>
                <div className="row align-items-start" style={{ display: 'inline-flex', margin: '2%', width: '94%' }}>
                    <div className="col-9">
                        {baseSelect({
                            title: 'Granularity',
                            defaultValue: granularityMapper[0],
                            options: granularityMapper,
                            onChange: (e) => setGranularity(e.value)
                        })}
                    </div>

                    <div className='col-3'>
                        <h5 htmlFor="start">From-To</h5>
                        <input style={{ float: 'left' }} type="date" id="start" name="trip-start" value={startDate} min="2018-01-01" max={today} onChange={(e) => setStartDate(new Date(e.target.value).toLocaleDateString('en-CA'))} />
                        <input type="date" id="end" name="trip-start" value={endDate} min="2018-01-01" max={today} onChange={(e) => setEndDate(new Date(e.target.value).toLocaleDateString('en-CA'))} />
                    </div>
                </div>
                <br />
                <button type="button" style={{ marginBottom: '50px', width: '100%' }} className="btn btn-primary" onClick={fetchStats}>Run</button>
                <br />
                {lastFetched ? <div style={{ float: 'right' }}>
                    <button type="button" onClick={() => fetchStats()} className="btn btn-link"><i className="bi bi-arrow-clockwise" /> </button>
                    <span >Last sync: {lastFetched.toLocaleDateString()}: {lastFetched.toLocaleTimeString()}</span>
                </div> : null}
                {tableValues ? (<div>

                    <h5>Gravite Marketplace Demand vs Supply</h5>

                    <br /><i>Gravite API does not offer profit as metric, this is calculated substracting NetRevenues from Revenues </i>
                    <br /><i>Some accounts have a dynamic share so here will be shown as Custom input, when initial supply profit is not zero, it is because some apps & NetworksKeys of that Publisher have predefined shares </i>
                    <br /><i>All the money values are in US Dollar</i>

                    <DataTable data={tableValues} onChangeShare={(id, newValue, columnName, mediation) => changeShare(id, newValue, columnName, mediation)} onSort={(property) => sorting(property)} />
                </div>) : null}
                {loading ? <Loader /> : null}

            </div>
        </div>
    )
};


const GmPages = ({ user, onLogout }) => {
    const [selected, setSelected] = useState(0)
    return (
        <div className="container-fluid">

            <ul className="nav nav-tabs">
                <li className="nav-item" onClick={() => setSelected(0)}>
                    <a className={selected === 0 ? 'nav-link active' : 'nav-link'} aria-current="page" href="#">GM Overview</a>
                </li>
                <li className="nav-item" onClick={() => setSelected(1)}>
                    <a className={selected === 1 ? 'nav-link active' : 'nav-link'} aria-current="page" href="#">GM comparison</a>
                </li>
            </ul>
            {selected === 0 ? <GMOverview user={user} onLogout={onLogout} /> : null}
            {selected === 1 ? <GMCompare user={user} onLogout={onLogout} /> : null}

        </div>
    )
}

const Wrapper = () => {
    return (<AuthLoginModal target='demand' style='rtb' open={true} fallback={(user, onLogout) => <GmPages user={user} onLogout={onLogout} />} />);
}

export default Wrapper